import toast from 'react-hot-toast'
import Message from 'constants/Message'

export const SuccessHandler = (msg?: { message: string; details?: string }) => {
  if (msg) {
    toast.success(msg.message)
    if (msg.details) return toast.success(msg.details)
  } else {
    return toast.success('Successful')
  }
}

export const ErrorHandler = (error?: {
  message?: string
  details?: string
  custom?: boolean
}) => {
  if (error) {
    if (error.message)
      return toast.error(error.message, {
        style: {
          background: 'Red',
          color: '#fff',
        },
      })
    if (error.custom) return toast.error(Message.ErrorResponseUndefined)
    if (error.details) return toast.error(error.details)
  } else {
    return toast.error(Message.ErrorResponseUndefined)
  }
}
