export const camelCaseToNormal = (initText: string) => {
  const replacedText = initText.replace(/([A-Z]+)/g, ' $1')
  const formattedText =
    replacedText.charAt(0).toUpperCase() + replacedText.slice(1)
  return formattedText
}

export const CaptializeText = (text: string) => {
  if (text) {
    const result = text.charAt(0).toUpperCase() + text.slice(1) || text

    return result
  }
  return ''
}
