import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import './TermsAndConditions.scss'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import Navigation from './navigation'

const TermsAndConditions = () => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Navigation />
        <Box className="layout">
          <Box className="content">
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#8F95B2',
                cursor: 'pointer',
                my: '2rem',
              }}
              onClick={handleBack}
            >
              <ArrowBack sx={{ fontSize: 15 }} />
              <Typography
                variant="inherit"
                sx={{ paddingLeft: '5px', fontSize: '0.825rem' }}
              >
                Back
              </Typography>
            </Grid>
            <Typography
              sx={{
                fontSize: '2rem',
                fontWeight: 600,
                lineHeight: '2.5rem',
                mb: '.6rem',
              }}
            >
              Fluna Investor Portal Terms and Conditions
            </Typography>
            <h4>
              Effective Date <span className="date">November 23, 2022</span>
            </h4>
            <section className="investor-agreement">
              <h4>Investor Agreement</h4>
              <p>
                This Agreement governs the relationship between you (the
                “Investor”) and Fluna Inc. (“Fluna”) and your use of the
                Investor Portal provided by Fluna at{' '}
                <a href="/" target="_blank" rel="noopener noreferrer">
                  {' '}
                  Investor Portal.
                </a>{' '}
                By accessing and using the Investor Portal in any manner, you
                accept and agree to be bound by the terms and conditions set out
                below (the “Terms”). This Agreement may be revised/updated by
                Fluna fromtime to time provided that all revisions/updates to
                this Agreement shall be communicated to you with acopy of the
                revised/updated Agreement via email. Your continued use of the
                Investor Portal after such notification shall be deemed an
                acceptance of the revised/updated Agreement. You should also
                review our{' '}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  Privacy Policy{' '}
                </a>{' '}
                for details on how we collect, use and process personal
                information provided to us in your use of the Investor portal.
              </p>
            </section>
            <section className="definitions">
              <h4>1. Definitions</h4>
              <p>
                Accredited Investor means anyone regarded as an accredited
                investor under Regulation D Rule506(b) – Rules Governing the
                Limited Offer and Sale of Securities Without Registration Under
                the Securities Act of 1933 as may be amended or repealed from
                time to time.
              </p>

              <article className="push-left">
                <p>
                  <strong>Bank Account</strong> means the Investor’s bank
                  account which may be linked to the Investor Portal.
                </p>

                <p>
                  <strong>Eligible Investor</strong> means anyone who is legally
                  permitted by a Securities and Exchange Commission or its
                  equivalent in its jurisdiction to invest in the Investment
                  Options.
                </p>

                <p>
                  <strong>Funds</strong> mean Money which you deposit in your
                  account for the purpose of investing in the Investment
                  Options.
                </p>

                <p>
                  <strong>Interest</strong> means the monthly sum accrued on the
                  Investment Amount in each Investment Option at the Interest
                  Rate.
                </p>

                <p>
                  <strong>Interest Rate</strong> means a percentage of the
                  Investment Amount which shall accrue monthly at the rate
                  specified for each Investment Option at the time of investing
                  the Funds.
                </p>

                <p>
                  <strong>Investor Account</strong> means the account that Fluna
                  creates for you on the Investor Portal when you sign up on the
                  Investor Portal.
                </p>

                <p>
                  <strong>Investment Amount</strong> means the portion of the
                  Funds invested in each Investment Option Investment Cycle
                  means in respect of each Investment Option, the period between
                  the investment of your Funds in the Investment Options until
                  Maturity.
                </p>

                <p>
                  <strong>Investment Options</strong> mean pre-vetted purchase
                  order or working capital loan opportunities and the related
                  commercial opportunities that we make available through the
                  Investor Portal from time to time that you may decide to
                  invest in via your Investment Account during the pendency of
                  this Agreement.
                </p>

                <p>
                  <strong>Material Breach</strong> means a breach that is
                  fundamental to the actualisation of the objectives of this
                  Agreement.
                </p>

                <p>
                  <strong>Maturity</strong> means the day on which an Investment
                  option will cease to bear Interest at the Interest Rate.
                </p>

                <p>
                  <strong>Money</strong> means any legal tender which is backed
                  up by a recognised government and which we determine to use in
                  connection with the Investor Portal.
                </p>

                <p>
                  <strong>Party</strong> means each of Fluna and the Investor
                  and Parties means both Fluna and the Investor.
                </p>

                <p>
                  <strong>You/Your</strong> means the Investor who uses the
                  Investor Portal and whose User Information was provided to
                  Fluna in the creation of an Investor Account and any other
                  person authorised by the Investor to use the Investor Portal.
                </p>

                <p>
                  <strong>Receiving Party</strong> means the Party receiving
                  Confidential Information from the Investment Manager.
                </p>

                <p>
                  <strong>Services</strong> mean the Services provided under
                  this Agreement.
                </p>

                <p>
                  <strong>Third Party Services</strong> means any link, website,
                  online application or service provided by Third Party
                  Providers that are linked to the Investor Portal which are not
                  controlled by Fluna and are outside the Investor Portal.
                </p>

                <p>
                  <strong>Third Party Providers</strong> mean any person or
                  entity providing Third Party Services.
                </p>

                <p>
                  <strong>User Identification</strong> means any username and
                  password created by you or given to you for the purpose of
                  accessing your Investor Account.
                </p>

                <p>
                  <strong>User Information</strong> means all information
                  requested by us, such as your name, email address,mobile
                  device number, online credentials for your Investor Account or
                  Bank Account, and such other information as we may request
                  from time to time
                </p>

                <p>
                  <strong>Us/We</strong> mean Fluna Inc.
                </p>

                <p>
                  <strong>Wallet</strong> means the cloud based storage that
                  allows the Investor to store, send, manage and receive funds.
                </p>
              </article>
            </section>
            <section className="investor-accounts">
              <h4>2. Investor Accounts</h4>
              <p>
                2.1. To use and access the Investor Portal, you must register
                for an Investor Account.
              </p>

              <p>
                2.2. By registering for an Investor Account, you confirm that
                you are an Accredited Investor or an Eligible Investor who is
                either an individual of at least 18 years or a trust or company.
              </p>

              <p>
                2.3. In providing your information for the creation of your
                Investor Account, you acknowledge andagree that all User
                Information provided to us is truthful, accurate, and complete.
                You agree to promptly notify us of any changes to your User
                Information by updating your record on the Investor Portal.
              </p>

              <p>
                2.4. All changes to your User Information on your Investor
                Account shall only be made with our consent and in compliance
                with our security procedures before they are accepted by us.
              </p>
            </section>
            <section className="investment">
              <h4> 3. Investment</h4>
              <p>
                3.1. Subject to the terms and conditions of this Agreement and
                for the duration of this Agreement, you are authorised solely
                for your personal use, to use your Investment Account for the
                following:
              </p>

              <article className="push-left">
                <p>
                  3.1.1. to deposit Funds to the Wallet in your Investment
                  Account
                </p>

                <p>
                  3.1.2. to use the Funds to invest in the Investment Options on
                  the Investor Portal from timeto time
                </p>

                <p>
                  3.1.3. to authorise and direct us to invest the Funds in your
                  choice of Investment Options
                </p>

                <p>
                  3.1.4. to request the withdrawal of your Funds to the value of
                  Funds from your Wallet into your Bank Account
                </p>
              </article>

              <p>
                3.2. During the duration of this Agreement, we shall make
                available to you the following services(“Services”):
              </p>

              <article className="push-left">
                <p>
                  3.2.1. provide you with Investment Options on the Investor
                  Portal;
                </p>

                <p>
                  3.2.2. invest your Funds in the Investment Options in
                  accordance with your instructions. You acknowledge that you
                  have no legal title or ownership or beneficial interest in the
                  Investment Options and are only entitled to request the
                  investment of your Funds in the Investment Options;
                </p>

                <p>
                  3.2.3. provide information on your Investor Account and the
                  various Investment Options which your Funds have been invested
                  in as directed by you.
                </p>

                <p>
                  3.2.4. pay any Interest (excluding any bank charges and fees)
                  accrued on your Investment Amount in each of the Investment
                  Options into your Wallet within 30 days of the end of each
                  Investment Cycle;
                </p>

                <p>
                  3.2.5. facilitate the withdrawal of Funds into your Bank
                  Account up to the balance of the Funds in your Wallet at any
                  time. The withdrawal may take up to five (5) business days to
                  arrive in your Bank Account.
                </p>
              </article>

              <p>3.3. You acknowledge that:</p>
              <article className="push-left">
                <p>
                  3.3.1. Fluna does not guarantee returns on the investment of
                  the Funds in the Investment Options.
                </p>

                <p>
                  3.3.2. Fluna shall not be held liable for lower than estimated
                  or no returns on the Investment Amounts or Funds.
                </p>

                <p>
                  3.3.3. You are an Accredited Investor or an Eligible Investor;
                </p>

                <p>3.3.4. Fluna is not a financial adviser;</p>

                <p>
                  3.3.5. The Investor Portal and Services are not intended to
                  provide financial advice.
                </p>

                <p>
                  3.4. The right to use your Investment Account is a
                  non-exclusive, non-transferable, non-assignable, and non-sub
                  licensable right granted to you by Fluna.
                </p>
              </article>
            </section>

            <section className="software-and-security-requirements">
              <h4> 4. Software and Security Requirements</h4>
              <p>
                4.1. You are required to safeguard your devices used to access
                your Investor Account including your User Identification. You
                must treat such information as confidential and not disclose the
                same to a third party. We are not responsible or liable to you
                for any unauthorised access to or use of your Investor Account,
                whether you are or are not aware except the unauthorised access
                resulted from Fluna’s failure to take commercially reasonable
                steps to secure the Investor Portal as may be expressly required
                under this Agreement or under applicable law.
              </p>

              <p>
                4.2. You acknowledge and agree that Fluna will not be liable for
                acting on or undertaking or following any instruction we receive
                through your Investor Account irrespective of whether such
                instructionwas not authorised by you, or if it was entered by
                mistake or if it was otherwise inaccurate. You are responsible
                for ensuring that your Investor Account is secure at all times
                and that instructions provided by you to Fluna are accurate.
              </p>

              <p>
                4.3. It is your responsibility to ensure that all malware
                required to protect your device is obtained. Fluna shall not be
                responsible for any loss or damage including loss of Funds or
                lock out fromInvestor Account suffered by you as a result of an
                unauthorised access of your Investor Accountor Third Party
                Provider software or a failure to keep your device safe and free
                from malware.
              </p>

              <p>
                4.4. We have the right to disable any User Identification
                connected with your Investor Account if inour opinion, you have
                failed to comply with the terms and conditions in this
                Agreement.
              </p>

              <p>
                4.5. If you know or suspect that anyone may have your User
                Identification, you must promptly notify us at help@fluna.co
                with details of the unauthorised access.
              </p>
            </section>

            <section className="Restrictions-On-Use-Of-The-Investor-Portal">
              <h4> 5. Restrictions on Your Use of the Investor Portal</h4>
              <p>
                5.1. You may only use the Investor Portal and the Services for
                your own personal, non-commercial useand not on behalf of or for
                the benefit of any third party.
              </p>

              <p>
                5.2. You must not authorise and will not permit any third party
                to authorise any other person or entityto access or use your
                User Identification on your Investor Account or the Investor
                Portal.
              </p>

              <p>
                5.3. You shall not open an Investor Account in a name that is
                not yours or provide any other falsifiedpersonal information or
                User Information.
              </p>

              <p>
                5.4. You shall not Reverse engineer, decompile, disassemble, or
                otherwise seek to obtain the sourcecode of the Investor Portal
                or Services.
              </p>

              <p>
                5.5. You shall not use the Investor Portal or Services in any
                way that is illegal, harmful, fraudulent,d eceptive,
                threatening, harassing, defamatory, obscene, or otherwise
                objectionable, as we may determine in our sole discretion
                including money laundering, illegal gambling, distributing or
                funding drugs or malicious hacking.
              </p>

              <p>
                5.6. You shall not attempt, in any manner, to obtain the
                username, password, account, or other security information from
                any other user of the Investor Portal
              </p>

              <p>
                5.7. You shall not circumvent (or attempt to circumvent) any
                access restrictions or security measures incorporated by Fluna
                into the Investor Portal or Services.
              </p>

              <p>
                5.8. You shall not directly or indirectly interfere with the
                proper functioning of the Investor Portal orServices.
              </p>

              <p>
                5.9. You shall not use, copy, modify, translate, adapt, merge,
                create derivative works of, distribute, sell, assign, pledge,
                sublicense, reproduce, lease, loan, rent, timeshare, deliver or
                otherwise transfer the Investor Portal, or any portion thereof,
                except in accordance with this Agreement.
              </p>

              <p>
                5.10. You shall not access the Investor Portal or Services in
                order to build a similar or competitive application, platform,
                service or software.
              </p>

              <p>
                5.11. You shall not directly or indirectly through the Investor
                Portal infringe any intellectual property rights of any person
                or entity
              </p>

              <p>
                5.12. You shall not and you shall not authorise any third party
                to engage in any potentially harmful actsdirected against the
                Investor Portal or Services, including violating any security
                features of the Investor Portal or Services, introducing
                viruses, worms, or similar harmful code into the Investor Portal
                or Services.
              </p>
            </section>
            <section className="Other-Conditions">
              <h4> 6. Other Conditions</h4>
              <p>
                6.1. In connection with your use of the Investor Portal, we may
                link Third Party Providers websites or services not owned or
                operated or controlled by Fluna. We will provide such links and
                connections for your reference only. The Third Party Services
                are subject to the terms of use specified by the Third Party
                Provider on its website and prior to and in order to receive
                such Third Party Services you will be required to agree to such
                terms of service, which will be presented for your agreement
                either by incorporation by reference in this Agreement or an
                online agreementthat you will be required to execute prior to
                accessing the Third Party Service.
              </p>

              <p>
                6.2. You shall be responsible for your use of the Third Party
                Services. It is your responsibility to read and understand the
                terms of use provided by the Third Party Providers before you
                agree to such terms as they contain certain terms and conditions
                relating to your Investor Account and Wallet. You agree that
                Fluna shall have no liability for your use of the Third Party
                Services.
              </p>

              <p>
                6.3. You shall have the right not to use any Third Party
                Services if you do not agree to the terms andconditions of the
                Third Party Provider, however, please note that this may affect
                your ability to use the Investor Portal and the Services. If you
                fail to comply with a Third Party Provider's terms of service,
                your use of the applicable Third Party Service may be suspended
                or terminated.
              </p>

              <p>
                6.4. In order to access the Investor Portal and the Services,
                you are required to agree to each of the Third Party Providers
                terms of services set out in Clauses 6.6 And 6.7 . By executing
                this Agreement, you hereby agree to the Third Party terms and
                conditions incorporated by reference inthis Agreement which form
                an agreement between you and the Third Party Provider. For the
                avoidance of doubt, Fluna is acting on behalf of the Third Party
                Provider only to secure your agreement to the Third Party
                Provider’s terms of use and nothing more.
              </p>

              <p>
                6.5. By creating an Investor Account you agree to Integrated
                Finance’s{' '}
                <a
                  href="https://integrated.finance/terms-and-conditions"
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  terms and conditions
                </a>
                , Integrated Finance’s{' '}
                <a
                  href="https://integrated.finance/privacy-policy"
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  privacy policy
                </a>
                , Integrated Finnace’s{' '}
                <a
                  href="https://integrated.finance/security-policy"
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  security policy
                </a>
                , Integrated Finance's{' '}
                <a
                  href="https://integrated.finance/cookie-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  cookie policy
                </a>{' '}
                and Currency Cloud’s{' '}
                <a
                  href="https://www.currencycloud.com/legal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  legal and policies.
                </a>
              </p>
            </section>
            <section className="User-Information">
              <h4> 7. User Information</h4>
              <p>
                7.1. You are solely responsible for the accuracy and
                completeness of the User Information you submit,and represent
                and warrant that you have all rights required in order to post,
                hold and use such User Information, including any Personal
                Information (as defined below). To the extent that youprovide us
                with, or we may have access to any information that allows us to
                identify you or anyother individual person (“Personal
                Information”) in connection with your use of the Investor Portal
                or Services, you acknowledge and agree that we will use and
                process such information as setforth in our{' '}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>

              <p>
                7.2. We do not provide and are not responsible for, and do not
                make any representations with respect to, the products or
                services of Third Party Providers or Third Party Services, and
                you use such Third Party Services at your own risk. Your access
                to and use of such Third Party Services are subject to the terms
                and conditions (including privacy policies) associated with that
                Third Party Provider. Fluna is not liable, and you agree not to
                seek to hold Fluna liable for the conduct of Third Party
                Provider or Third Party Services, and that in each case, the
                risk of injury from such Third Party Provider rests entirely
                with you. When you click on a link to a hyperlink to a Third
                Party Service, we may not warn you that you have left the
                Investor Portal or Services, and that your activity is now
                governed by the terms and conditions of that Third Party
                Provide.
              </p>
            </section>
            <section className="Indemnity">
              <h4> 8. Indemnity</h4>
              <p>
                8.1. You agree to indemnify Fluna, subsidiaries, affiliates,
                officers, employees, agents, partners and licensors
                (collectively, the “Fluna Parties”) to the full extent permitted
                by applicable law against all losses, damages and costs, demands
                or expenses relating to any actions, proceedings, claims,
                brought by any third party against the Fluna Parties as a result
                of your use of the Investor Portalor the Services, violation of
                applicable law, gross negligence, fraud or wilful misconduct
                except where such losses, damages and costs, demands or expenses
                is as a result of Fluna’s Parties’ fraud, gross negligence or
                material breach of this Agreement.
              </p>

              <p>
                8.2. Where any action, proceeding or claim contemplated under
                clause 8.1 is brought by a third partyagainst the Fluna Parties,
                Fluna, may but is not obligated to at its own cost assume the
                defence and control of any matter subject to indemnification by
                you. You agree to fully cooperate with Fluna where Fluna decides
                to undertake any defence pursuant to this clause 8.2.
              </p>
            </section>
            <section className="Revisions-and-Updates-to-this-Agreement">
              <h4> 9. Revisions and Updates to this Agreement</h4>
              <p>
                9.1. Fluna may at its sole discretion revise or update this
                Agreement at any time for any reason including to reflect
                changes in applicable law or updates to your Investor Account
                and to reflect any changes to the Investor Portal.
              </p>

              <p>
                9.2. All revisions/updates to this Agreement shall be
                communicated to you with a copy of the revised/updated Agreement
                via email or as a notification within the Investor Portal.
              </p>

              <p>
                9.3. Your continued use of the Investor Portal after such
                notification shall be deemed an acceptance of the
                revised/updated Agreement.
              </p>

              <p>
                9.4. You may terminate this Agreement if you do not wish to
                continue your use of the Investor Portal and or the Services
                upon receipt of a revision or update to this Agreement by
                sending a written notice of termination to Fluna.
              </p>
            </section>
            <section className="Revisions-and-Updates-to-this-Agreement-2">
              <h4> 10. Revisions and Updates to this Agreement</h4>
              <p>
                You acknowledge and agree that Fluna may delegate to a third
                party or engage a third party toassist Fluna with the
                performances of any or all parts of the Services under this
                Agreement. You also agree that Fluna may provide your User
                Information to the Third Party engaged to provide the Services
                and hereby consent to the use of your User Information by the
                Third Party in connection with the performance of the Services.
                In providing your User Information to the Third Party we shall
                ensure that the Third Party complies with the terms and
                conditions of this Agreement including protecting the
                confidentiality of your User Information.
              </p>
            </section>
            <section className="Notices">
              <h4> 11. Notices</h4>
              <p>
                11.1. Any notice, consent, approval or request required or
                permitted to be given or made under this Agreement shall be in
                writing and in English Language. Such notice, consent, approval
                or request shall be deemed to be duly given or made when it
                shall have been delivered by hand, mail, or any other electronic
                means to the Party to which is required to be given.
              </p>

              <p>
                11.2. In your case, all notices, consent, approval or request
                required or permitted to be given or made under this Agreement
                delivered by electronic mail to you shall be deemed delivered
                when Fluna sends the email, to the email provided as part of
                your User Information on your Investor Account.
              </p>
            </section>
            <section className="Commencement-and-Termination">
              <h4> 12. Commencement and Termination</h4>
              <p>
                12.1. This Agreement shall apply at all times to your use of the
                Investor Portal unless terminated in accordance with Clause 12.2
                and 12.3 below. 12.2. You may terminate this Agreement and close
                your Investor Account at any time and request for a withdrawal
                of all your Funds in your Investor Account provided that:
              </p>

              <p>
                12.2.1. If you terminate this Agreement, you will be required to
                deactivate any recurring payments from your Bank Account to your
                Wallet in your Investor Account.
              </p>

              <p>
                12.2.2. If upon termination, any portion of your Fund is
                invested in an Investment Option, you shall be entitled to
                receive your Investment Amount along with any accrued Interest
                at the date of termination within thirty (30) days of the end of
                the Investment Cycle.
              </p>

              <p>
                12.3. We may terminate this Agreement and permanently restrict,
                suspend or limit your access to theInvestor Portal if we suspect
                that you have violated our{' '}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{' '}
                or for any of the following reasons:
              </p>

              <article className="push-left">
                <p>
                  12.3.1. If we are instructed to do so by a government or law
                  enforcement agency.
                </p>

                <p>
                  12.3.2. If we reasonably suspect that you are utilising or
                  attempt to use your Investor Account for illegal purposes
                  including money laundering, gambling, financing of terrorism,
                  funding of drug trafficking or malicious hacking.
                </p>

                <p>
                  12.3.3. If you attempt to overcome any of our security
                  features.
                </p>

                <p>
                  12.3.4. If you breach any of the terms and conditions in this
                  Agreement.
                </p>

                <p>
                  12.3.5. If you breach any of our intellectual property rights
                  and those of any third parties.
                </p>
              </article>

              <p>
                12.4. If Fluna suspends or restricts or terminates your access
                to the Investor Portal or Services, Fluna shall use commercially
                reasonable efforts to give you prior notification of pending
                suspension of at least twelve (12) hours, unless Fluna
                determines, in its absolute discretion, that a shorter or
                nonotice is necessary to protect the Investor Portal, Fluna and
                other users of the Investor Portal. Fluna shall not be liable
                for any claims or damages of any kind arising out of a
                reasonable suspension of the Investor Portal or Services under
                clause 12.3.
              </p>

              <p>
                12.5. If Fluna suspects or becomes aware that you may have
                broken any of the terms of this Agreement, Fluna may, in its
                sole discretion, investigate and take any of the steps outlined
                in Section 12.3 above. In the event that there has been an
                actual or suspected inappropriate, illegal, or fraudulent access
                to or use of your Investor Account or any other Investor
                Account, you shall completely cooperate with us in our
                investigations or requests for information.
              </p>

              <p>
                12.6. You agree that you will not be able to access the Investor
                Portal for any purpose whatsoever or any information on the
                Investor Portal upon termination of this Agreement.
              </p>

              <p>
                12.7. Notwithstanding the termination of this Agreement, the
                following clause shall remain applicable: Clauses 1, 5, 7, 9,
                14, and 12.
              </p>
            </section>
            <section className="Force-Majeure">
              <h4> 13. Force Majeure</h4>
              <p>
                13.1. Any delay in, or failure to provide the Investor Portal or
                the Services under this Agreement by Fluna shall not constitute
                a breach of this Agreement or give rise to any claim for damages
                against Fluna if such delay or failure of performance is caused
                by a Force Majeure Event.
              </p>

              <p>
                13.2. For the purpose of this clause, a Force Majeure Event
                shall mean an abnormal and unforeseenevent or circumstance
                beyond the reasonable control of Fluna, including without
                limitation, acts of God, civil commotion, riots, insurrection,
                acts of government, fire, explosion, epidemics, pandemics,
                governmental embargoes, quarantines, war, strikes, lockouts,
                flood or earthquakes.
              </p>

              <p>
                13.3. Where Fluna is affected by a Force Majeure Event, Fluna
                shall notify you of the occurrence andshall use commercially
                reasonable efforts to mitigate the consequences of the Force
                Majeure Event.
              </p>

              <p>
                13.4. In the event that the delay or failure caused by the Force
                Majeure Event lasts for more than three (3) months, Fluna may
                terminate this Agreement immediately by written notice to you.
              </p>
            </section>
            <section className="Limitation-of-Liability">
              <h4> 14. Limitation of Liability</h4>
              <p>
                14.1. The Investor Portal and Services are provided to you on an
                “AS IS” and “ASAVAILABLE” basis without any warranty of any
                kind. To the full extent permitted byapplicable law, we and our
                Third Party Providers expressly disclaim any and all conditions,
                representations and warranties of any kind as to your use of the
                Investor Portal and Services, including any implied or statutory
                warranties of performance, merchantability, availability,
                accuracy, fitness for a particular purpose.
              </p>

              <p>
                14.2. You acknowledge that to the extent allowed by applicable
                law, all risk of use of the Investor Portal and Services rests
                entirely on you.
              </p>

              <p>
                14.3. Without limiting the foregoing, we and our Third Party
                Providers make no warranty that:
              </p>

              <article className="push-left">
                <p>
                  14.3.1. The Investor Portal will operate correctly, will be
                  available 100% of the time, will function without interruption
                  or that it is error-free.
                </p>

                <p>
                  14.3.2. There will be no delays, failures, errors omissions of
                  loss of transmitted information
                </p>

                <p>
                  14.3.3. The Investor Portal will be able to access all Third
                  Party Providers platforms for the duration of this Agreement.
                </p>

                <p>
                  14.3.4. any errors in the Investor Portal or Services will be
                  corrected.
                </p>

                <p>
                  14.3.5. Any material downloaded or otherwise obtained through
                  the Investor Portal will not result in any damage to your
                  computer system or loss of data that results from the download
                  of any material.
                </p>
              </article>

              <p>
                14.4. Fluna shall not be liable to you in, in contract, tort or
                otherwise, for any indirect or consequential losses, damages,
                costs, charges, expenses or otherwise, loss of business
                opportunity, loss of revenue, profit or anticipated profit which
                you suffer out of or in connection with your use of the Investor
                Portal or the Services whether or not Fluna had been advised of
                the possibility of such losses and regardless of any theory of
                liability asserted.
              </p>

              <p>
                14.5. You acknowledge and agree that you are solely responsible
                for any taxes or levies on any revenue that may accrue to you as
                a result of your use of the Investor Portal or the Services.
              </p>

              <p>
                14.6. If for any reason clauses 14.1 and 14.2 are found or
                declared inoperative by a court of competent jurisdiction,
                unenforceable or invalid and liability is imposed on Fluna, you
                agree that Fluna’s aggregate liability to you shall not exceed
                the aggregate amount received by you as Interest for the
                preceding six (6) calendar months or for such lesser period in
                which the Investor Portal has been used by you.
              </p>

              <p>
                14.7. You acknowledge and agree that Fluna has entered into this
                Agreement in reliance of the disclaimers, limitations and
                exclusions of liability contained in this clause 14 and that the
                disclaimers, limitations and exclusions of liability form an
                essential part of Fluna’s agreement to allow your use of the
                Investor Portal and Fluna will not be able to allow your use of
                the Investor Portal or provide the Services to you without the
                disclaimers, limitations and exclusions of liability contained
                in this clause 14.
              </p>
            </section>
            <section className="General-Provisions">
              <h4> 15. General Provisions</h4>
              <p>
                15.1. If any term or provision of this Agreement is determined
                to be illegal, unenforceable, or invalid in whole or in part for
                any reason, such illegal, unenforceable, or invalid provision or
                part(s) there of shall be struck from this Agreement, and such
                provision shall not affect the legality, enforceability, or
                validity of the remainder of this Agreement, and the provision
                struck off shall be replaced, to the extent possible, with a
                legal, enforceable, and valid provision that is as similar in
                tenor to the provision struck off as is legally possible.
              </p>

              <p>
                15.2. You may not voluntarily, by operation of law, or
                otherwise, assign any of your rights or delegate any of your
                duties, hypothecate, give, transfer, mortgage, sublet, licence,
                or otherwise transfer or encumber all or part of your rights,
                duties, or other interests in this Agreement or the proceeds
                ofwithout the Fluna’s prior written consent. We may transfer or
                assign or delegate our rights and obligations hereunder without
                your consent to the extent permitted by law.
              </p>

              <p>
                15.3. This Agreement contains the whole of the agreement between
                the Parties relating to the matters dealt with herein and, save
                to the extent otherwise provided herein, no undertaking,
                representation, discussions, term or condition relating to the
                subject matter of this Agreement not incorporated in this
                Agreement shall be binding on any of the Parties.
              </p>

              <p>
                15.4. No variation, addition, deletion, waiver, of this
                Agreement or any part hereof, including this paragraph, is
                effective unless reduced to writing and signed by or on behalf
                of Parties, or in the case of a waiver, by the Party so waiving
                its right in writing.
              </p>
            </section>

            <section className="Electronic-Signature">
              <h4> 16. Electronic Signature</h4>
              <p>
                16.1. You agree that this Agreement and any documents executed,
                scanned, and transmitted electronically or digitally and digital
                signatures shall be deemed original and valid signatures for
                purposes of this Agreement and all matters related there to,
                with such scanned and electronic or digital signatures having
                the same legal effect as traditional signatures.
              </p>

              <p>
                16.2. You agree that your consent in Clause 16.1 will remain
                effective unless expressly withdrawn.
              </p>
            </section>
            <section className="Governing-Law-and-Dispute-Resolution">
              <h4> 17. GOVERNING LAW AND DISPUTE RESOLUTION</h4>
              <p>
                17.1. This Agreement is governed by the laws of the State of
                Delaware.
              </p>

              <p>
                17.2. In the event of any controversy or claim arising out of or
                relating to this Agreement, the Parties shall attempt in good
                faith to resolve such controversy, claim or dispute promptly by
                negotiation between the Parties or their authorised
                representatives.
              </p>

              <p>
                17.3. The Parties or their representatives will meet either
                personally or through other electronic communication, at a
                mutually acceptable time and place within seven (7) days of the
                date of the aggrieved Party’s notice and thereafter as often as
                they deem necessary to exchange relevant information and to
                resolve the dispute.
              </p>

              <p>
                17.4. If the controversy, claim or dispute is not resolved
                within Fourteen (14) days of thecommencement of negotiations
                between the parties, such controversy or claim arising out of
                orrelating to this Agreement, or the breach thereof, shall be
                settled by arbitration administered bythe American Arbitration
                Association in accordance with its Commercial Arbitration Rules,
                and judgement on the award rendered by the arbitrator(s) may be
                entered in any court having jurisdiction thereof.
              </p>
            </section>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default TermsAndConditions
