import React, { lazy } from 'react'
export const LoginPage = lazy(() => import('pages/v2/authentication/login'))
export const ForgotPasswordPage = lazy(
  () => import('pages/v2/authentication/forgotPassword')
)
export const VerifyPage = lazy(() => import('pages/v2/authentication/verify'))
export const ResetPasswordPage = lazy(
  () => import('pages/v2/authentication/resetPassword')
)
export const RegisterPage = lazy(
  () => import('pages/v2/authentication/register')
)
export const RegisterLandingPage = lazy(
  () => import('pages/v2/authentication/register/landing')
)
export const RegisterForm = lazy(
  () => import('pages/v2/authentication/register/form')
)
export const VerifyRegistration = lazy(
  () => import('pages/v2/authentication/register/verify')
)
export const TransferAcknowledgement = lazy(
  () => import('pages/v2/fundWallet/TransferAcknowledgement')
)

const AuthRoutes = [
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    path: 'verify',
    element: <VerifyPage />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: 'reset-password',
    element: <ResetPasswordPage />,
  },
  {
    path: 'register',
    element: <RegisterPage />,
    children: [
      {
        path: '',
        element: <RegisterForm />,
      },
      {
        path: 'welcome',
        element: <RegisterLandingPage />,
      },
      {
        path: 'verification',
        element: <VerifyRegistration />,
      },
    ],
  },
  {
    path: 'funding/transfer-sent/:id',
    element: <TransferAcknowledgement />,
  },
  {
    path: '',
    element: <LoginPage />,
  },
]

export default AuthRoutes
