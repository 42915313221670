import Box from '@mui/material/Box'
import React, { ReactNode } from 'react'

interface ContentWallProps {
  children: ReactNode
}
const ContentWall: React.FC<ContentWallProps> = ({ children }) => {
  return (
    <Box
      sx={{
        maxWidth: '1536px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '1.5rem',
        '@media (min-width: 1024px)': {
          padding: '2rem',
        },
        scrollBehavior: 'smooth',
      }}
    >
      {children}
    </Box>
  )
}

export default ContentWall
