import React from 'react'
import { getCurrentAuthenticatedUser } from 'services/v2/authService'

const AuthProvider = (props: { children: React.ReactElement }) => {
  const { children } = props
  React.useEffect(() => {
    const initialize = async () => {
      await getCurrentAuthenticatedUser()
    }

    initialize()
  }, [])

  return <>{children}</>
}

export default AuthProvider
