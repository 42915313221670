import { configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice'
import fundingSlice from './fundingSlice'
import accountBalancesSlice from './accountBalancesSlice'
import investmentSlice from './investmentSlice'
import transactionActivitySlice from './transactionActivitySlice'

import loanbookSlice from './loanbookSlice'
export const store = configureStore({
  reducer: {
    auth: authSlice,
    funding: fundingSlice,
    balances: accountBalancesSlice,
    investments: investmentSlice,
    transactionActivity: transactionActivitySlice,
    loanBook: loanbookSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
