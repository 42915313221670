// hooks/useActivityTracker.tsx
import { Auth } from 'aws-amplify'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setLogout } from '../redux/v2/authSlice'
import { MixPanel } from '../services/mixpanel' // Ensure logout is an async thunk action
import { isDevelopment } from '../utils/helpers'

const useActivityTracker = () => {
  const dispatch = useDispatch()

  const handleLogout = useCallback(async () => {
    try {
      await Auth.signOut()
      MixPanel.signout()
      await dispatch(setLogout()) // Dispatch the logout action
    } catch (error) {
      console.error('Error during auto-logout:', error)
    }
  }, [dispatch])

  useEffect(() => {
    if (isDevelopment()) {
      return
    }

    let timeoutId: NodeJS.Timeout

    const resetTimer = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(handleLogout, 900000) // 15 minutes
    }

    // Initialize timer
    resetTimer()

    // Add event listeners
    window.addEventListener('load', resetTimer)
    window.addEventListener('mousemove', resetTimer)
    window.addEventListener('mousedown', resetTimer)
    window.addEventListener('touchstart', resetTimer)
    window.addEventListener('click', resetTimer)
    window.addEventListener('keydown', resetTimer)
    window.addEventListener('scroll', resetTimer, true)

    // Cleanup function
    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('load', resetTimer)
      window.removeEventListener('mousemove', resetTimer)
      window.removeEventListener('touchstart', resetTimer)
      window.removeEventListener('click', resetTimer)
      window.removeEventListener('keydown', resetTimer)
      window.removeEventListener('scroll', resetTimer, true)
    }
  }, [handleLogout])
}

export default useActivityTracker
