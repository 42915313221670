import DashboardLayout from 'layout/v2/dashboard/DashboardLayout'
import React, { lazy } from 'react'
import { SettingsChildren } from './SettingsRoutes'
// import { data } from 'data/v2/statementSampleData'

// export const Statement = lazy(
//   () =>
//     import('pages/v2/transactionActivity/drawers/transactionActivityStatement')
// )
export const FundWallet = lazy(() => import('pages/v2/fundWallet'))
export const WithdrawFunds = lazy(() => import('pages/v2/withdrawFunds'))
const Investment = lazy(() => import('../../pages/v2/investment'))
const NewInvestment = lazy(() => import('../../pages/v2/investment/new'))
export const Dashboard = lazy(() => import('pages/v2/dashboard/index'))
export const Settings = lazy(() => import('pages/v2/settings/index'))
export const Help = lazy(() => import('pages/v2/help/index'))
export const TransactionActivity = lazy(
  () => import('pages/v2/transactionActivity/index')
)

const GeneralRoutes = [
  {
    path: '',
    element: <DashboardLayout />,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'fund-wallet',
        element: <FundWallet />,
      },
      {
        path: 'withdraw-funds',
        element: <WithdrawFunds />,
      },
      {
        path: 'settings',
        element: <Settings />,
        children: [...SettingsChildren],
      },
      {
        path: 'transactions',
        element: <TransactionActivity />,
      },
      // {
      //   path: 'statement',
      //   element: (
      //     <Statement
      //       data={data}
      //       userDetails={{
      //         firstName: 'David Pobi',
      //         lastName: 'Ofosu-Dorte',
      //         country: 'Ghana',
      //         state: 'Greater Accra',
      //         city: 'Accra',
      //         street: '28 Apple Streetss',
      //       }}
      //       investmentPortfolios={[
      //         {
      //           investmentCurrency: 'USD',
      //           activeCurrencyInvestmentsValue: 10730,
      //           interestEarned: 1052.5900000000001,
      //           walletBalance: '299.58',
      //         },
      //       ]}
      //       statementQuarter="2024-07-01T00:00:00+01:00 -> 2024-09-30T23:59:59+01:00"
      //     />
      //   ),
      // },
      {
        path: 'investments/:investmentCardID',
        element: <Investment />,
      },
      {
        path: 'investments',
        element: <Investment />,
      },
      {
        path: 'investments/new',
        element: <NewInvestment />,
      },
      {
        path: 'investments/edit/:id',
        element: <NewInvestment />,
      },
      {
        path: 'help',
        element: <Help />,
      },
    ],
  },
]

export default GeneralRoutes
