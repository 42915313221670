import React from 'react'
import { Typography, SxProps, Theme } from '@mui/material'

interface BodyTextProps {
  text: string
  isSmallText?: boolean
  color?: string
  sx?: SxProps<Theme>
}

interface HeaderTextProps {
  text: string
  color?: string
  sx?: SxProps<Theme>
}

export const HeaderText: React.FC<HeaderTextProps> = ({ text, color, sx }) => (
  <Typography
    sx={{
      fontWeight: 600,
      fontSize: { xs: '1.375rem', sm: '1.5rem', md: '1.625rem' },
      lineHeight: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
      color: color || 'inherit',
      ...sx,
    }}
  >
    {text}
  </Typography>
)

export const SubHeaderText: React.FC<HeaderTextProps> = ({
  text,
  color,
  sx,
}) => (
  <Typography
    sx={{
      fontWeight: 600,
      fontSize: { xs: '1.25rem', sm: '1.325rem', md: '1.5rem' },
      lineHeight: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
      color: color || 'inherit',
      ...sx,
    }}
  >
    {text}
  </Typography>
)

export const BodyText: React.FC<BodyTextProps> = ({
  text,
  isSmallText = false,
  color,
  sx,
}) => (
  <Typography
    sx={{
      fontWeight: 400,
      fontSize: {
        xs: '0.895rem',
        sm: '0.895rem',
        md: isSmallText ? '0.875rem' : '1rem',
      },
      lineHeight: {
        xs: isSmallText ? '1.25rem' : '1.5rem',
        sm: isSmallText ? '1.25rem' : '1.5rem',
        md: isSmallText ? '1.5rem' : '1.75rem',
      },
      color: color || 'inherit',
      ...sx,
    }}
  >
    {text}
  </Typography>
)
