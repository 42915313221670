import React, { lazy } from 'react'

export const Security = lazy(() => import('pages/v2/settings/security/index'))

export const SettingsChildren = [
  {
    path: 'security',
    element: <Security />,
  },
]
