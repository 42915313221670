/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import flunaLogo from 'assets/Images/flunaLogoHD.png'
import MenuLink from 'components/Links/MenuLink'
import { SideNavigation, SettingsNav } from 'constants/v2/Navigation'
import { Typography } from '@mui/material'
import { useAppSelector } from 'redux/v2/hooks'
import { KycVerificationStatus } from 'API'

interface DrawerProps {
  openDrawer: boolean
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileSideNav = ({ openDrawer, setOpenDrawer }: DrawerProps) => {
  const opened = true
  const { user } = useAppSelector((state) => state.auth)
  const { kycVerificationStatus } = user

  const isKycVerified =
    kycVerificationStatus === KycVerificationStatus.finished ||
    kycVerificationStatus === KycVerificationStatus.verified

  const notKycVerified = !isKycVerified

  const handleCloseDrawer = (event: React.SyntheticEvent) => {
    event.preventDefault()
    setOpenDrawer(false)
  }

  const list = () => (
    <>
      <Box
        sx={{
          width: 250,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#212437',
        }}
        role="presentation"
        onClick={handleCloseDrawer}
        onKeyDown={handleCloseDrawer}
        data-testid="mobileSidebar"
      >
        <Box
          sx={{
            margin: '1.6rem',
          }}
        >
          <img
            alt="Fluna"
            src={flunaLogo}
            style={{ objectFit: 'contain', width: '128px' }}
          />
        </Box>

        {SideNavigation.map((page) => (
          <Typography
            onClick={handleCloseDrawer}
            component={'div'}
            key={page.name}
            sx={{
              color: '#171721',
              cursor: 'pointer',
              margin: '1.3rem 0',
            }}
          >
            <MenuLink
              key={page.name}
              page={page}
              opened={true}
              allowComing
              allowNotif
              disabled={
                notKycVerified &&
                !(page.name === 'Dashboard' || page.name === 'Help')
              }
            />
          </Typography>
        ))}
        <Box
          sx={{
            marginTop: 'auto',
          }}
        >
          {SettingsNav.map((page) => (
            <MenuLink
              key={page.name}
              page={page}
              opened={opened}
              allowComing
              allowNotif
            />
          ))}
        </Box>
      </Box>
    </>
  )

  return (
    <React.Fragment>
      <Drawer anchor="left" open={openDrawer} onClose={handleCloseDrawer}>
        {list()}
      </Drawer>
    </React.Fragment>
  )
}

export default MobileSideNav
