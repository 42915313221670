import * as React from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import { LinkProps } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'

interface UpdatedProps extends LinkProps {
  disableMatch?: boolean
  disabled?: boolean
}

function CustomLink({
  children,
  disableMatch,
  disabled,
  to,
  ...props
}: UpdatedProps) {
  const updatedPath = to + '/*'
  const resolved = useResolvedPath(updatedPath)
  const match = useMatch({ path: resolved.pathname, end: true })

  const matches = useMediaQuery('(min-width:900px)')

  if (disabled) {
    // Render a non-link element when disabled is true
    return (
      <span
        style={{
          textDecoration: 'none',
          color: '#D5D7E3',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          lineHeight: '20px',
          fontSize: '16px',
          opacity: 0.7,
        }}
        {...props}
      >
        {children}
      </span>
    )
  }

  return (
    <>
      {matches ? (
        <Link
          style={{
            textDecoration: 'none',
            color: match && !disableMatch ? '#E77A0C' : '#D5D7E3',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            //  padding: match && !disableMatch ? '6px 0 0 30px' : '6px 0',
            lineHeight: '20px',
            fontSize: '16px',
          }}
          to={to}
          {...props}
        >
          {children}
        </Link>
      ) : (
        <Link
          style={{
            textDecoration: 'none',
            color: match && !disableMatch ? '#E77A0C' : '#D5D7E3',
            padding: '0px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          to={to}
          {...props}
        >
          {children}
        </Link>
      )}
    </>
  )
}

export default CustomLink
