export interface IAccountDetails {
  id?: string
  accountName: string
  bankName: string
  accountType: string
  accountNumber: string
  routingType?: string
  routingNumber: string
  swiftCode?: string
  ibanCode: string
  country?: string
  bankCountry?: string
  bankAddress: string
  personalAddress: string
  recipientCountry?: string
  bankPostalCode?: string
  postalCode?: string
  memo?: string
}

export enum AccountType {
  PersonalChecking = 'PersonalChecking',
  PersonalSavings = 'PersonalSavings',
  BusinessChecking = 'BusinessChecking',
  BusinessSavings = 'BusinessSavings',
}

export interface InterestPayout {
  amount: number
  currency: string
  expectedDate: string
}

export interface IAccountBalances {
  walletBalance: number | null
  activeInvestment: number
  totalBalance: number
  weightedAnnualInterestRate?: number
  nextInterestPayout?: InterestPayout
  lastWithdrawalAmount?: number
  lifetimeInterestEarned?: number
}

export interface IDepositInformation {
  chaps: Chaps
  fasterPayments: FasterPayments
  swift: Swift
  internal: Internal
}

export interface RoutingCodes {
  sortCode?: string
  bic?: string
}

export interface Chaps {
  accountHolderName: string
  country: string
  currency: string
  routingCodes: RoutingCodes
  accountNumber: string
  iban: string
  ledgerNumber?: any
  reference?: any
  bankName?: any
  bankAddress?: any
  intermediaryAccount?: any
}

export interface FasterPayments {
  accountHolderName: string
  country: string
  currency: string
  routingCodes: RoutingCodes
  accountNumber: string
  iban: string
  ledgerNumber?: any
  reference?: any
  bankName?: any
  bankAddress?: any
  intermediaryAccount?: any
}

export interface IntermediaryAccount {
  accountHolderName?: any
  country: string
  currency: string
  routingCodes: RoutingCodes
  accountNumber?: any
  iban?: any
  ledgerNumber?: any
  reference?: any
  bankName: string
  bankAddress?: any
  intermediaryAccount?: any
}

export interface Swift {
  accountHolderName: string
  country: string
  currency: string
  routingCodes: RoutingCodes
  accountNumber: string
  iban: string
  ledgerNumber?: any
  reference?: any
  bankName: string
  bankAddress?: any
  intermediaryAccount: IntermediaryAccount
}

export interface Internal {
  accountHolderName: string
  country: string
  currency: string
  routingCodes: RoutingCodes
  accountNumber?: any
  iban?: any
  ledgerNumber: string
  reference?: any
  bankName?: any
  bankAddress?: any
  intermediaryAccount?: any
}

export enum BankAccountTypes {
  USD = 'USD',
  NGN = 'NGN',
}
export interface IBankAccountsSliceState {
  bankAccountType: BankAccountTypes | null
  isUpdateState: boolean
}
export interface Quarter {
  label: string
  period: string
  startDate: Date
  endDate: Date
}
