import { KycVerificationStatus } from 'API'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux/v2/hooks'
import { baseRoute } from 'utils/helpers'

const permittedRoutes = [
  `${baseRoute}/dashboard`,
  `${baseRoute}/transactions`,
  `${baseRoute}/help`,
  `${baseRoute}/help/guides`,
  `${baseRoute}/help/guides/1`,
  `${baseRoute}/help/contact`,
  `${baseRoute}/onboarding`,
  `${baseRoute}/onboarding/status`,
  `${baseRoute}/settings`,
]

interface KycGuardProps {
  children: React.ReactElement
}

const KycGuard: React.FC<KycGuardProps> = ({ children }) => {
  const location = useLocation()
  const { user } = useAppSelector((state) => state.auth)
  const isKycVerified =
    user.kycVerificationStatus === KycVerificationStatus.finished ||
    user.kycVerificationStatus === KycVerificationStatus.verified

  const notKycVerified = !isKycVerified

  const isPermittedRoute = permittedRoutes.some((route) =>
    location.pathname.startsWith(route)
  )

  if (notKycVerified && !isPermittedRoute) {
    return <Navigate to={`${baseRoute}/dashboard`} />
  }

  return <>{children}</>
}

export default KycGuard
