import { Box, Grid, Typography } from '@mui/material'
import { PropsWithChildren, useEffect } from 'react'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { useAppSelector } from 'redux/v2/hooks'
import { baseRoute } from 'utils/helpers'

const NoBreak = ({ children }: PropsWithChildren) => {
  return (
    <Box
      component="span"
      sx={{
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </Box>
  )
}

const texts = {
  register: {
    MainText: () => (
      <>
        Get started with <NoBreak>high-yield</NoBreak>, investments
      </>
    ),
    SubText: () => (
      <>
        Vetted, <NoBreak>FX-neutral</NoBreak>, trade finance opportunities
        powering global <NoBreak>cross-border</NoBreak> trade
      </>
    ),
  },
  login: {
    MainText: () => (
      <>
        Get right back into <Box component="br" /> your investments
      </>
    ),
    SubText: () => (
      <>
        Reconnect with Your Investments and <Box component="br" /> Explore New
        Opportunities
      </>
    ),
  },
}

const AuthenticationLayout = () => {
  const location = useLocation()
  const { isAuthenticated } = useAppSelector((state) => state.auth)

  const registerPath = ['register', 'onboarding'].some((route) =>
    location.pathname.includes(route)
  )

  if (isAuthenticated && location.pathname === '/')
    return <Navigate to={`${baseRoute}/dashboard`} />

  const { MainText, SubText } = registerPath ? texts.register : texts.login

  const ScrollToTop = () => {
    const { pathname } = useLocation()

    useEffect(() => {
      const rightColumn = document.getElementById('right-column')
      if (rightColumn) {
        rightColumn.scrollTop = 0
      }
    }, [pathname])

    return null
  }

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ScrollToTop />
      <Grid
        item
        md={0}
        lg={4}
        component="section"
        sx={{
          position: 'relative',
          height: '100vh',
        }}
      >
        <Box
          sx={(theme) => ({
            position: 'fixed',
            top: 0,
            left: 0,
            width: '33.33%',
            height: '100vh',
            overflowY: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            webkitOverflowScrolling: 'touch',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'bottom',
            backgroundImage:
              'url(/static/lines.png), url(/static/auth-bg.png), linear-gradient(159.18deg, #08175F 3.73%, #FBA34B 109.91%)',
            [theme.breakpoints.down('lg')]: {
              display: 'none',
            },
          })}
        >
          <Box
            sx={{
              height: 'calc(100vh - 7rem)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '3.5rem 1.875rem',
            }}
          >
            <Box>
              <Link to={`${baseRoute}/login`}>
                <Box
                  component="img"
                  src="/flunaLogoWhite.png"
                  alt="Logo png"
                  sx={{
                    width: '135px',
                    cursor: 'pointer',
                  }}
                />
              </Link>
            </Box>
            <Box
              sx={{
                color: 'rgba(255, 255, 255, 1)',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '2.5rem',
                  lineHeight: '2.983rem',
                  mb: '1.2rem',
                  mt: 5,
                  letterSpacing: '0.03em',
                }}
              >
                <MainText />
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.25rem',
                  lineHeight: '1.75rem',
                  fontWeight: 400,
                  mb: 4,
                  letterSpacing: '0.01em',
                }}
              >
                <SubText />
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '1rem',
                  color: 'rgba(224, 224, 224, 1)',
                  letterSpacing: '0.01em',
                }}
              >
                &copy; Fluna Inc 2024
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={8}
        id="right-column"
        component="section"
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '2rem',
          overflowY: 'scroll',
        }}
      >
        <Box
          sx={{
            width: '100%',
            margin: '0 auto',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Outlet />
        </Box>
      </Grid>
    </Grid>
  )
}

export default AuthenticationLayout
