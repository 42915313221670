export const getUserCustom = /* GraphQL */ `
  query GetUserCustom($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      taxId
      country
      state
      city
      nationality
      referral
      hearAboutUs
      street
      postalCode
      legalAgreements
      identification {
        idType
        idNumber
        taxIdType
        taxId
      }
      usAccreditedInvestor {
        annualIncome
        netWorth
        includesSpouse
        accreditedInvestorCondition
        accreditedInvestorFlag
        accreditedInvestorExemption
        __typename
      }
      bankAccountPayoutUSD {
        accountName
        accountType
        accountNumber
        routingType
        routingNumber
        bankName
        iban
        swiftCode
        bankPostalCode
        bankCountry
        currency
        country
        bankAddress
        recipientCountry
        recipientAddress
        memo
        __typename
      }
      bankAccountPayoutNGN {
        accountName
        accountType
        accountNumber
        achRoutingNumber
        bankName
        iban
        bankPostalCode
        recipientCountry
        currency
        country
        bankAddress
        recipientAddress
        memo
        __typename
      }
      kycDetails {
        type
        identificationNumber
        issuingCountry
        issueDate
        expirationDate
        reason
        verificationId
        documentPhotos
        verifiedDelivered
        rejectedDelivered
        __typename
      }
      Documents {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      minimumInvestment
      minimumDeposit
      payments {
        nextToken
        __typename
      }
      monthlyInterestBankPayouts {
        nextToken
        __typename
      }
      dueInterestBankPayouts {
        nextToken
        __typename
      }
      withdrawals {
        nextToken
        __typename
      }
      loans {
        nextToken
        __typename
      }
      investments {
        nextToken
        __typename
      }
      references {
        items {
          id
          reference
          amount
          currency
          Status
          notes
          userID
        }
        nextToken
        __typename
      }
      fxTransactions {
        nextToken
        __typename
      }
      clientId
      accountId
      wallets {
        USD {
          id
          accountNumber
          currency
        }
        NGN {
          id
          accountNumber
          currency
        }
        __typename
      }
      otpCode {
        code
        timestamp
        __typename
      }
      onboardingStatus
      kycVerificationStatus
      monthlyInterestPayoutsOptIn
      owner
      __typename
    }
  }
`

export const getReferencesCustom = /* GraphQL */ `
  query GetReferencesCustom($id: ID!) {
    getReferences(id: $id) {
      id
      Status
      ProofOfPaymentUrl
      createdAt
      updatedAt
    }
  }
`

export const getInvestmentsByUserCustom = /* GraphQL */ `
  query GetInvestmentsByUserCustom(
    $userID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvestmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInvestmentsByUser(
      userID: $userID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        actualEndDate
        annualInterestRate
        renewFlag
        renewOption
        currency
        principleAmount
        InterestCycleDays
        tenorDays
        expectedTotalInterest
        expectedInterestPerInterestCycle
        expectedNumberOfInterestCycles
        interestPayoutOption
        investmentStep
        funded
        fundedAt
        Status
        createdAt
        updatedAt
        userID
        user {
          id
          firstName
          lastName
          email
          dateOfBirth
          phoneNumber
          taxId
          country
          state
          city
          street
          postalCode
          legalAgreements
          createdAt
          updatedAt
          minimumInvestment
          minimumDeposit
          clientId
          accountId
          onboardingStatus
          kycVerificationStatus
          monthlyInterestPayoutsOptIn
          owner
        }
        references {
          nextToken
          items {
            id
            Status
            amount
            ProofOfPaymentUrl
            createdAt
            updatedAt
          }
        }
        fxTransactions {
          nextToken
          items {
            Status
            ProofOfPaymentUrl
            createdAt
            updatedAt
          }
        }
        payments {
          items {
            id
            paymentCategory
            paymentType
            currency
            amount
            expectedDate
            actualDate
            initiatedBy
            notes
            Status
            createdAt
            updatedAt
            userID
            investmentID
            owner
          }
          nextToken
        }
        monthlyInterestBankPayouts {
          nextToken
        }
        dueInterestBankPayouts {
          nextToken
        }
        investmentAgreement {
          signed
          signedAgreementUrl
          signedAt
          signature
        }
        isBackdated
        nextInterestPerInterestCycle
        owner
      }
      nextToken
    }
  }
`

export const getInvestmentCustom = /* GraphQL */ `
  query GetInvestmentCustom($id: ID!) {
    getInvestment(id: $id) {
        id
        startDate
        endDate
        actualEndDate
        annualInterestRate
        renewFlag
        renewOption
        currency
        principleAmount
        InterestCycleDays
        tenorDays
        expectedTotalInterest
        expectedInterestPerInterestCycle
        expectedNumberOfInterestCycles
        interestPayoutOption
        funded
        fundedAt
        Status
        createdAt
        updatedAt
        userID
        user {
            id
            firstName
            lastName
            email
            dateOfBirth
            phoneNumber
            nationality
            hearAboutUs
            referral
            taxId
            taxIdType
            country
            state
            city
            street
            postalCode
            legalAgreements
            createdAt
            updatedAt
            minimumInvestment
            minimumDeposit
            clientId
            accountId
            onboardingStatus
            kycVerificationStatus
            monthlyInterestPayoutsOptIn
            migrationStatus
            owner
            __typename
        }
        payments {
            items {
                id
                Status
                paymentCategory
                paymentType
                amount
                currency
            }
            nextToken
            __typename
        }
        monthlyInterestBankPayouts {
            nextToken
            __typename
        }
        dueInterestBankPayouts {
            nextToken
            __typename
        }
        references {
            items {
                id
                investmentID
                Status
                ProofOfPaymentUrl
            }
            nextToken
            __typename
        }
        fxTransactions {
            nextToken
            __typename
        }
        investmentAgreement {
            signed
            signature
            signedAgreementUrl
            signedAt
            __typename
        }
        isBackdated
        nextInterestPerInterestCycle
        isRenewed
        investmentStep
        owner
        __typename
    }
}
`

export const updateReferencesCustom = /* GraphQL */ `
  mutation UpdateReferencesCustom(
    $input: UpdateReferencesInput!
    $condition: ModelReferencesConditionInput
  ) {
    updateReferences(input: $input, condition: $condition) {
      id
      Status
      ProofOfPaymentUrl
      createdAt
      updatedAt
    }
  }
`
