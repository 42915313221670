function importAll(r) {
  return r.keys().map(r)
}

const fonts = importAll(
  require.context('./assets/fonts/SF-Pro', false, /\.(otf|ttf|woff|woff2)$/)
)

const styleOrder = [
  'HeavyItalic',
  'BoldItalic',
  'SemiboldItalic',
  'MediumItalic',
  'RegularItalic',
  'LightItalic',
  'UltralightItalic',
  'ThinItalic',
  'Heavy',
  'Bold',
  'Semibold',
  'Medium',
  'Regular',
  'Light',
  'Ultralight',
  'Thin',
]

const fontWeights = {
  Thin: 100,
  Ultralight: 200,
  Light: 300,
  Regular: 400,
  Medium: 500,
  Semibold: 600,
  Bold: 700,
  Heavy: 900,
}

const uniqueArray = (arrayWithDuplicates) =>
  arrayWithDuplicates.reduce((accumulator, currentValue) => {
    if (!accumulator.includes(currentValue)) {
      accumulator.push(currentValue)
    }
    return accumulator
  }, [])

const getFontWeight = (val) => {
  const [fontName] = val.split('.')
  const [, , , fontWeight] = fontName.split('-')
  return fontWeight
}

const customSort = (a, b) => {
  const aWeight = getFontWeight(a.font)
  const bWeight = getFontWeight(b.font)

  const indexA = styleOrder.indexOf(aWeight)
  const indexB = styleOrder.indexOf(bWeight)

  return indexA - indexB
}

const clonedFonts = JSON.parse(JSON.stringify(uniqueArray(fonts)))
const arrayWeights = Object.keys(fontWeights)
const weightedFonts = clonedFonts.map((font) => {
  const fontWeight = getFontWeight(font)
  let weightToUse = ''
  arrayWeights.forEach((weight) => {
    if (fontWeight && fontWeight.includes(weight)) weightToUse = weight
  })

  return {
    weight: fontWeights[weightToUse],
    font,
  }
})
const sortedFontPaths = weightedFonts.sort(customSort)

const loadFonts = () => {
  let fontStyles = ``

  sortedFontPaths.forEach(({ font, weight }) => {
    const fontName = 'SF Pro Display'
    const fontText = `
      @font-face {
        font-family: "${fontName}";
        src: url(${font}) format("opentype");
        font-style: normal; 
        font-weight: ${weight || 'normal'}
      }
    `
    fontStyles = `${fontStyles} ${fontText}`
  })

  const style = document.createElement('style')
  style.appendChild(document.createTextNode(''))
  style.classList.add('sf-pro-font')
  style.textContent = fontStyles
  document.head.appendChild(style)
}

export default loadFonts
