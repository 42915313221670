import React from 'react'
import { useMatch, useResolvedPath } from 'react-router-dom'
import CustomLink from './CustomLink'
import { Box, Tooltip } from '@mui/material'
import { Children } from 'interfaces/Route'

export type Navs = {
  name: string
  navigation: string
  element?: any
  notif?: number
  coming?: boolean
  children?: Children[]
  secondarySidebar?: boolean
}

interface MenuLinkProps {
  page: Navs
  allowComing?: boolean
  allowNotif?: boolean
  opened: boolean
  disabled?: boolean
  setShowing?: React.Dispatch<React.SetStateAction<string>>
}

function MenuLink({
  page,
  opened,
  disabled,
  allowComing,
  allowNotif,
  setShowing,
}: MenuLinkProps) {
  const updatedPath = page.navigation + '/*'
  const resolved = useResolvedPath(updatedPath)
  const match = useMatch({ path: resolved.pathname, end: true })

  return (
    <>
      <Box
        data-testid="menu-link"
        key={page.name}
        sx={{
          color: '#171721',
          cursor: disabled ? 'not-allowed' : 'pointer',
          padding: '8px 18px',
          margin: '4px 0px',
          fontSize: {
            xs: '16px',
            sm: '16px',
            md: '1rem',
          },
          fontWeight: '400',
          display: 'flex',
          alignItems: 'center',
          justifyContent: opened ? 'space-between' : 'center',
          borderLeft: match ? '4px solid #E77A0C' : '4px solid #212437',
        }}
      >
        <CustomLink
          to={page.navigation}
          disabled={disabled}
          onClick={() => setShowing && setShowing(page.name)}
        >
          <Box
            alignItems="center"
            mr="1rem"
            fontSize={opened ? '1.2em' : '1.4em'}
            display={'inline-block'}
          >
            {opened ? (
              page.element
            ) : (
              <Tooltip title={`${page.name}`} placement="right-start">
                {page.element}
              </Tooltip>
            )}
          </Box>
          {opened ? page.name : ''}
          {allowComing && opened && page.coming ? (
            <Box
              sx={{
                backgroundColor: '#3E7EFF',
                color: '#fff',
                borderRadius: '4px',
                margin: '1px 2px',
                padding: '1px 4px',
                fontSize: '0.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Coming soon
            </Box>
          ) : (
            ''
          )}
        </CustomLink>

        {allowNotif && opened && page.notif ? (
          <Box
            sx={{
              backgroundColor: '#3E7EFF',
              color: '#fff',
              borderRadius: '50%',
              position: 'relative',
              top: '8px',
              fontSize: {
                xs: '10px',
                sm: '0.6rem',
                md: '0.6rem',
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '20px',
              height: '20px',
            }}
          >
            {page.notif}
          </Box>
        ) : (
          ''
        )}
      </Box>
    </>
  )
}

export default MenuLink
