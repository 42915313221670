export const MIN_DEPOSIT_USD = 500
export const MIN_DEPOSIT_NGN = 2000000
export const MAX_DEPOSIT_USD = 100000
export const MAX_DEPOSIT_NGN = 5000000
export const MIN_INVESTMENT = 500
export const MIN_INVESTMENT_USD = 500
export const MIN_INVESTMENT_NGN = 2000000
export const MAX_INVESTMENT_USD = 20000
export const MAX_INVESTMENT_NGN = 100000000
export const INTEREST_CYCLE_DAYS = 90
export const maxHeightChild = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}
