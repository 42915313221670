import { createSlice } from '@reduxjs/toolkit'
import { initialInvestment } from 'data/v2/investmentData'
import { Investment } from '../../API'

export enum CurrencyTypes {
  USD = 'USD',
  NGN = 'NGN',
}
export interface investmentState {
  savedInvestment?: Investment | null
  investments: Investment[]
  totalAmountInvested: Record<CurrencyTypes, number>
  isUpdateState: boolean
  isSignAgreementState: boolean
}
const initialState: investmentState = {
  savedInvestment: null,
  investments: initialInvestment,
  isUpdateState: false,
  totalAmountInvested: { USD: 0, NGN: 0 },
  isSignAgreementState: false,
}

const investmentSlice = createSlice({
  name: 'investment',
  initialState,
  reducers: {
    setSavedInvestment: (state, action) => {
      state.savedInvestment = action.payload.data
    },
    getAllInvestment: (state, action) => {
      state.investments = action.payload
      state.totalAmountInvested = (action.payload as Investment[]).reduce(
        (acc, item) => {
          if (item.funded && item.currency) {
            const currency = item.currency.toLowerCase()
            const amount = item?.principleAmount || 0

            if (currency === 'usd') {
              acc.USD += amount
            } else if (currency === 'ngn') {
              acc.NGN += amount
            }
          }
          return acc
        },
        { USD: 0, NGN: 0 } // Initial values for total investments
      )
    },
    setUpdateState: (state, action) => {
      state.isUpdateState = action.payload.state
      state.isSignAgreementState = !!action.payload.isSignAgreementState
    },
  },
})

export const { getAllInvestment, setUpdateState, setSavedInvestment } =
  investmentSlice.actions

export default investmentSlice.reducer
