import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Avatar } from '@mui/material'
import { useNavigate } from 'react-router'
import { Auth } from 'aws-amplify'
import { RootState } from 'redux/store'
import { useAppSelector } from 'redux/hooks'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp'
import MobileSideNav from '../mobileSideNav/MobileSideNav'

const Navbar = () => {
  const navigate = useNavigate()
  const [anchorElDrawer, setAnchorElDrawer] =
    React.useState<null | HTMLElement>(null)
  const [sideNavOpen, setSideNavOpen] = React.useState(false)
  const user = useAppSelector((state: RootState) => state.auth.user)

  const handleOpenSideNav = () => {
    //  e.preventDefault()
    setSideNavOpen(true)
  }

  const handleOpenPopupMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDrawer(event.currentTarget)
  }

  const handleClosePopupMenu = () => {
    setAnchorElDrawer(null)
  }

  const stringAvatar = (name: string) => {
    return {
      sx: {
        backgroundColor: {
          xs: '#8AB0FB2B',
          sm: '#0550E6',
          md: '#0550E6',
        },
        color: {
          xs: '#212437',
          sm: '#212437',
          md: '#212437',
        },
        width: {
          xs: '1.875rem',
          sm: '2.5rem',
          md: '2.5rem',
        },
        height: {
          xs: '1.875rem',
          sm: '2.5rem',
          md: '2.5rem',
        },
        fontSize: {
          xs: '.75rem',
          sm: '1.25rem',
          md: '1.25rem',
        },
        fontWeight: {
          xs: 600,
          sm: 500,
          md: 500,
        },
      },
      children: `${name.split(' ')[0][0]}${
        name.split(' ')[1][0]
      }`.toUpperCase(),
    }
  }

  const signOut = async () => {
    handleClosePopupMenu()
    await Auth.signOut()
    window.location.reload()
    navigate('/login')
  }

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        width: '100%',
        backgroundColor: '#ffffff',
        borderBottom: '1px solid #E4E6E8',
        position: 'sticky',
        top: '0',
        zIndex: 1,
        padding: {
          xs: '0',
          sm: '0 1rem',
          md: '0 1rem',
          lg: '0 4rem',
        },
      }}
    >
      <Toolbar
        component={'div'}
        sx={{
          height: 'auto',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'none',
            },
            p: '0',
          }}
        >
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ color: '#8F95B2' }}
            onClick={handleOpenSideNav}
          >
            <MenuIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'none', sm: 'none', md: 'flex' },
          }}
        >
          <Typography
            sx={{ color: '#17224D', fontSize: '1.125rem', fontWeight: 600 }}
          ></Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 0,
            display: 'flex',
            alignItems: 'center',
            gap: {
              xs: '.625rem',
              sm: '0rem',
              md: '0rem',
            },
          }}
        >
          <IconButton
            sx={{
              p: 0,
              color: {
                xs: '#8F95B2',
              },
            }}
          ></IconButton>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: '#171721',
              padding: {
                xs: '0rem 0rem',
                sm: '0rem .75rem',
                md: '0rem .75rem',
              },
              cursor: 'pointer',
              gap: {
                xs: '.625rem',
                sm: '0rem',
                md: '0rem',
              },
            }}
            data-testid="menuBtn"
            onClick={handleOpenPopupMenu}
          >
            <Avatar
              {...stringAvatar(`${user.firstName} ${user.lastName}`)}
              sx={{
                background: '#8AB0FB2B',
                color: '#212437',
                fontSize: '1rem',
                fontWeight: 600,
                mr: 1,
              }}
            />
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 600,
                padding: '0rem .375rem',
                display: {
                  xs: 'none',
                  sm: 'block',
                  md: 'block',
                },
              }}
            >
              {`${user.firstName} ${user.lastName}`}
            </Typography>

            <IconButton
              sx={{
                position: 'relative',
                left: '0rem',
                p: 0,
                color: {
                  xs: '#8F95B2',
                },
              }}
            >
              <KeyboardArrowDownSharpIcon sx={{ fontSize: '1.37rem' }} />
            </IconButton>
          </Box>
          <Menu
            sx={{ mt: '2.813rem', bg: 'red' }}
            //id="menu-appbar"
            anchorEl={anchorElDrawer}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElDrawer)}
            onClose={handleClosePopupMenu}
          >
            <MenuItem onClick={handleClosePopupMenu}>
              <Typography
                textAlign="center"
                onClick={() => {
                  signOut()
                }}
                sx={{ fontSize: '1rem' }}
              >
                Sign Out
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>

      <MobileSideNav openDrawer={sideNavOpen} setOpenDrawer={setSideNavOpen} />
    </AppBar>
  )
}
export default Navbar
