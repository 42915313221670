import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Button, { ButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'

export interface CustomButtonProps extends ButtonProps {
  text: string | React.ReactNode
  disabled?: boolean
  loading?: boolean
  loadingText?: string
  breakpoint?: Record<'sm' | 'md' | 'lg' | 'xl', number>
  hover?: boolean
  variant?: 'text' | 'outlined' | 'contained'
  focusSelected?: boolean
  iconOnly?: boolean
  icon?: React.ReactNode
  route?: string
  textColor?: string
  externalStyle?: React.CSSProperties
  endIcon?: React.ReactNode
}

const ButtonAlt = styled(
  ({
    text,
    disabled,
    loading,
    loadingText,
    hover = true,
    variant = 'contained',
    focusSelected,
    iconOnly,
    icon,
    endIcon,
    route,
    textColor,
    breakpoint = { sm: 600, md: 960, lg: 1280, xl: 1920 },
    externalStyle,
    ...rest
  }: CustomButtonProps) => {
    const navigate = useNavigate()

    const [, setButtonState] = useState<
      'default' | 'focused' | 'disabled' | 'hover'
    >(disabled ? 'disabled' : 'default')

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      rest.onClick?.(event)

      if (route) {
        navigate(route)
      }
    }

    const backgroundColorMap = {
      default: {
        filled: '#FBA34B',
        text: 'transparent',
        outline: 'transparent',
      },
      hover: {
        filled: '#FFBC79',
        outline: '#F2F3F7',
        text: '#F5F5FA',
      },
      disabled: 'rgba(251, 163, 75, 0.3)',
    }

    const colorMap = {
      disabled: 'rgba(61, 30, 1, 0.3)',
      default: '#3D1E01',
    }

    const backgroundColor =
      variant === 'contained'
        ? backgroundColorMap.default.filled
        : backgroundColorMap.default.text

    const color = textColor ?? colorMap.default

    const getPadding = (size: number) => {
      if (size >= breakpoint.xl) {
        return '1rem 2rem'
      } else if (size >= breakpoint.lg) {
        return '0.813rem 1.5rem'
      } else if (size >= breakpoint.md) {
        return '0.813rem 1rem'
      } else {
        return '0.813rem 0.75rem'
      }
    }

    const handleMouseEnter = () => {
      setButtonState('hover')
    }

    const handleMouseLeave = () => {
      setButtonState(disabled ? 'disabled' : 'default')
    }

    const handleFocus = () => {
      setButtonState('focused')
    }

    const handleBlur = () => {
      setButtonState(disabled ? 'disabled' : 'default')
    }

    return iconOnly ? (
      <IconButton
        {...rest}
        onClick={handleButtonClick}
        sx={{
          color: color,
          ...(hover && {
            '&:hover': {
              backgroundColor: backgroundColorMap.hover.filled,
            },
          }),
          ...externalStyle,
        }}
      >
        {icon}
      </IconButton>
    ) : (
      <Button
        {...rest}
        onClick={handleButtonClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled || loading}
        variant={variant}
        sx={{
          borderRadius: '8px',
          padding: getPadding(window.innerWidth),
          fontSize: '.875rem',
          fontWeight: 500,
          letterSpacing: 0.5,
          lineHeight: '1.25rem',
          textTransform: 'capitalize',
          backgroundColor: backgroundColor,
          boxShadow: 'none',
          cursor: 'pointer',
          color: color,
          border: variant === 'outlined' ? '1px solid  #E9EAEF' : 'none',
          ...(hover && {
            '&:hover': {
              boxShadow: 'none',
              backgroundColor:
                variant === 'contained'
                  ? backgroundColorMap.hover.filled
                  : backgroundColorMap.hover.text,
            },
          }),
          ...(focusSelected && {
            '&.Mui-selected': {
              backgroundColor: backgroundColorMap.hover.filled,
              border: '2px solid #FDD6AF',
              boxShadow: 'none',
            },
          }),
          ...externalStyle,
        }}
        startIcon={!iconOnly && icon}
        endIcon={!iconOnly && endIcon}
      >
        {loading ? (
          <Box
            component="span"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '.5rem',
            }}
          >
            <CircularProgress size={15} />
            <Box component="span">{loadingText ?? text}</Box>
          </Box>
        ) : (
          text
        )}
      </Button>
    )
  }
)``

export default ButtonAlt
