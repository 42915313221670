import {
  References,
  Withdrawals,
  Payments,
  DueInterestBankPayouts,
  Investment,
} from 'API'
import { API } from 'aws-amplify'

interface GetAccountTransactionsResult {
  success: boolean
  data: IFetchTransactionsResult
}

export type modifiedTransactions<T> = {
  amount?: number | null
  status?: string
  type?: string
  destination?: string
} & T

interface IFetchTransactionsResult {
  principal: modifiedTransactions<Investment>[]
  deposits: modifiedTransactions<References>[]
  withdrawals: modifiedTransactions<Withdrawals>[]
  interests: modifiedTransactions<Payments>[]
  bankPayouts: modifiedTransactions<DueInterestBankPayouts>[]
}

export const getAccountTransactionsUngrouped = async (
  uid: string,
  clientId: string,
  accountId: string
) => {
  let { success, data }: GetAccountTransactionsResult = {
    success: false,
    data: {
      deposits: [],
      withdrawals: [],
      principal: [],
      interests: [],
      bankPayouts: [],
    },
  }

  try {
    const apiName = 'flunaIFServer'
    const path = '/if-transactions-history'

    const config = {
      headers: {},
      response: true,
      queryStringParameters: {
        uid: uid,
        clientId: clientId,
        accountId: accountId,
      },
    }
    const result = await API.get(apiName, path, config)
    const items: IFetchTransactionsResult = result.data.transactions

    const filteredPrincipal = items.principal.filter(
      (x) =>
        x.type === 'Principal' &&
        x.destination === 'Investment Wallet' &&
        x.status?.toLowerCase() !== 'cancelled'
    )

    const filteredInterests = items.interests.filter(
      (x) =>
        x.type === 'Interest' &&
        x.destination === 'Wallet' &&
        x.status?.toLowerCase() !== 'cancelled'
    )

    const filteredDeposits = items.deposits.filter(
      (x) => x.status?.toLowerCase() !== 'cancelled'
    )

    const filteredWithdrawals = items.withdrawals.filter(
      (x) => x.status?.toLowerCase() !== 'cancelled'
    )

    const filteredBankPayouts = items.interests.filter(
      (x) =>
        x.type === 'InterestPayout' &&
        x.destination === 'Bank' &&
        x.status?.toLowerCase() !== 'cancelled'
    ) as unknown as modifiedTransactions<DueInterestBankPayouts>[]

    const transactions = {
      deposits: filteredDeposits,
      withdrawals: filteredWithdrawals,
      principal: filteredPrincipal,
      interests: filteredInterests,
      bankPayouts: filteredBankPayouts,
    }

    success = true
    data = transactions
  } catch (err) {
    console.log(err)
    success = false
    data = {
      deposits: [],
      withdrawals: [],
      principal: [],
      interests: [],
      bankPayouts: [],
    }
  }

  return { success, data }
}
