import React, { ReactNode } from 'react'
import { Grid } from '@mui/material'

interface CenteredContentProps {
  children: ReactNode
}
const CenteredContent = ({ children }: CenteredContentProps) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '80vh', minWidth: '100%' }}
    >
      <Grid
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        item
      >
        {children}
      </Grid>
    </Grid>
  )
}

export default CenteredContent
