import AuthenticationLayout from 'layout/v2/authentication/AuthenticationLayout'
import Loading from 'components/Loading'
import Error from '../../components/v2/Error'
import AuthRoutes from './AuthRoutes'
import AuthGuardStatus from 'auth/AuthGuardStatus'
import OnboardingRoutes from './OnboardingRoutes'
import GeneralRoutes from './GeneralRoutes'
import KycGuard from 'auth/KycGuard'

import { Outlet } from 'react-router'
import PrivacyPolicy from 'pages/v2/policies/privacyPolicy'
import TermsAndConditions from 'pages/v2/policies/termsAndConditions'
const routes = [
  {
    path: '/',
    element: (
      <Loading>
        <AuthenticationLayout />
      </Loading>
    ),
    children: [...AuthRoutes],
  },
  {
    path: '/',
    element: (
      <AuthGuardStatus>
        <KycGuard>
          <Loading>
            <Outlet />
          </Loading>
        </KycGuard>
      </AuthGuardStatus>
    ),
    children: [...GeneralRoutes, ...OnboardingRoutes],
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/terms',
    element: <TermsAndConditions />,
  },
  {
    path: '*',
    element: <Error title="Page Not Found" type="404" />,
  },
]

export default routes
