/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from 'interfaces/User'

interface ILoginData {
  email: string
  password: string
}

interface ILoginData {
  email: string
  password: string
}

interface AuthState {
  user: Partial<IUser>
  loginData: ILoginData | null
  loginReturn: { [key: string]: any } | null
  onboardingStatus: string | null
  isInitialized: boolean
  isAuthenticated: boolean
  twoFaStatus: string
}

const initialState: AuthState = {
  loginData: null,
  loginReturn: null,
  onboardingStatus: null,
  user: {},
  isInitialized: false,
  isAuthenticated: false,
  twoFaStatus: '',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setOnboardingStatus: (state, action: PayloadAction<string>) => {
      state.onboardingStatus = action.payload
    },
    setLoginInfo: (
      state,
      action: PayloadAction<{
        loginData: ILoginData
        loginReturn: { [key: string]: any }
      }>
    ) => {
      state.loginData = action.payload.loginData
      state.loginReturn = action.payload.loginReturn
    },
    setLogout: (state) => {
      state.isAuthenticated = false
      state.user = {}
    },
    setTwoFaSuccess: (state, action: PayloadAction<{ status: string }>) => {
      state.twoFaStatus = action.payload.status
    },
    setCurrentUser: (
      state,
      action: PayloadAction<{
        attributes: { [key: string]: any }
        isAuthenticated: boolean
      }>
    ) => {
      state.user = action.payload.attributes
      state.isAuthenticated = action.payload.isAuthenticated
      state.isInitialized = true
    },
  },
  extraReducers: (builder) => {},
})

export const {
  setOnboardingStatus,
  setLoginInfo,
  setTwoFaSuccess,
  setCurrentUser,
  setLogout,
} = authSlice.actions

export default authSlice.reducer
