import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'
import Lottie from 'react-lottie-player'
import { useNavigate } from 'react-router-dom'
import { baseRoute } from '../../../utils/helpers'
import ButtonAlt from '../Button'
import CenteredContent from '../Content/CenteredContent'
import ContentWall from '../Content/ContentWall'
import notfound from 'assets/animations/404_state.json'
import error from 'assets/animations/error_state.json'
import { SubHeaderText } from '../Typography/Texts'
import HomeIcon from '@mui/icons-material/Home'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
interface ErrorProps {
  title: string
  type: '404' | 'error'
  onClick?: () => void
}
const Error: React.FC<ErrorProps> = ({ title, type, onClick }) => {
  const navigate = useNavigate()

  const supportClick = () => {
    onClick && onClick()
    navigate(`${baseRoute}/help?tab=help`)
  }

  const dashboardNavigation = () => {
    const location = window.location
    const dashboardURL = `${baseRoute}/dashboard`

    if (location.href === dashboardURL) {
      location.reload()
    } else {
      navigate(dashboardURL)
    }
  }

  const StyledBox = styled(Box)(({ theme }) => ({
    maxWidth: '32.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    margin: 'auto',
  }))

  return (
    <ContentWall>
      <CenteredContent>
        <StyledBox sx={{ width: '100%' }}>
          <Lottie
            play
            loop
            style={{ width: 330, height: 440 }}
            animationData={type === '404' ? notfound : error}
          />
          <SubHeaderText text={title} />
          <Box
            mt={4}
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            gap={4}
          >
            <ButtonAlt
              text="Contact Support"
              variant="outlined"
              sx={{
                border: '1px solid #BBBEC2 !important',
              }}
              onClick={supportClick}
              icon={<ContactSupportIcon sx={{ color: '#3D1E01' }} />}
            />
            <ButtonAlt
              text="Go to Dashboard"
              variant="contained"
              icon={<HomeIcon sx={{ color: '#3D1E01' }} />}
              onClick={dashboardNavigation}
            />
          </Box>
        </StyledBox>
      </CenteredContent>
    </ContentWall>
  )
}

export default Error
