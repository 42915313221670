import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as Overview } from 'assets/Images/Overview.svg'
import { ReactComponent as Investment } from 'assets/Images/inventory.svg'
import { ReactComponent as Transactions } from 'assets/Images/loanbook.svg'
import { ReactComponent as Settings } from 'assets/Images/settings.svg'
import { ReactComponent as Help } from 'assets/Images/help.svg'
import { baseRoute } from 'utils/helpers'

export const SideNavigation = [
  {
    name: 'Dashboard',
    navigation: `${baseRoute}/dashboard`,
    element: <SvgIcon component={Overview} inheritViewBox color="inherit" />,
    notif: 0,
    coming: false,
  },
  {
    name: 'Investments',
    navigation: `${baseRoute}/investments`,
    element: <SvgIcon component={Investment} inheritViewBox color="inherit" />,
    notif: 0,
    coming: false,
  },
  {
    name: 'Transaction Activity',
    navigation: `${baseRoute}/transactions`,
    element: (
      <SvgIcon component={Transactions} inheritViewBox color="inherit" />
    ),
    notif: 0,
    coming: false,
  },
  {
    name: 'Help',
    navigation: `${baseRoute}/help`,
    element: <SvgIcon component={Help} inheritViewBox color="inherit" />,
    notif: 0,
    coming: false,
  },
]

export const SettingsNav = [
  {
    name: 'Settings',
    navigation: `${baseRoute}/settings`,
    element: <SvgIcon component={Settings} inheritViewBox color="inherit" />,
    notif: 0,
    coming: false,
  },
]
