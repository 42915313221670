import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { baseRoute } from 'utils/helpers'

const Navigation = () => {
  return (
    <Box
      sx={{
        backgroundImage:
          'url(/static/lines.png), linear-gradient(159.18deg, #08175F 3.73%, #FBA34B 109.91%)',
        padding: '1.813rem',
        backgroundSize: '100% 400px, cover',
        backgroundPosition: 'top left, center, center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Link to={`${baseRoute}/login`}>
        <Box
          component="img"
          src="/flunaLogoWhite.png"
          alt="Logo png"
          sx={{
            width: '135px',
            cursor: 'pointer',
          }}
        />
      </Link>
    </Box>
  )
}

export default Navigation
