import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import './TermsAndConditions.scss'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import Navigation from './navigation'

const PrivacyPolicy = () => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Navigation />
        <Box className="layout">
          <Box className="content">
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#8F95B2',
                cursor: 'pointer',
                my: '2rem',
              }}
              onClick={handleBack}
            >
              <ArrowBack sx={{ fontSize: 15 }} />
              <Typography
                variant="inherit"
                sx={{ paddingLeft: '5px', fontSize: '0.825rem' }}
              >
                Back
              </Typography>
            </Grid>
            <Typography
              sx={{
                fontSize: '2rem',
                fontWeight: 600,
                lineHeight: '2.5rem',
                mb: '.6rem',
              }}
            >
              Fluna Investor Portal Terms and Conditions
            </Typography>
            <section className="Introduction">
              <h4>1. Introduction</h4>
              <p>
                <a
                  href="https://www.fluna.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Fluna Investor Portal
                </a>{' '}
                is provided by Fluna Inc (“Fluna”). At Fluna, we value your
                privacy, and we are committed to safeguarding your personal
                information. All personal data that you provide us will be
                protected and kept confidential among our affiliates,
                representatives, and privies. Throughout the website, the terms
                “we”, “us” and “our” refer to Fluna. This Privacy Policy
                (“Policy”) explains how we collect, use, share and protect your
                personal data in connection with your relationship with us as a
                user or potential user. It applies to all our clients, potential
                clients, consultants, partners and every other person we hold
                information about. This Policy also sets out your rights and who
                you may contact for further information. You agree to this
                Privacy Policy by visiting our website and when you use our
                services. Your use of our website, services and application, and
                any dispute over privacy is subject to this Policy and our Terms
                and Conditions, including its applicable limitations on damages
                and the resolution of disputes. Our Terms of Service are
                incorporated by reference into this Policy. Our website and
                services are not directed at you if we are prohibited by any law
                of any jurisdiction from making the information on our website
                available to you and is not intended for any use that would be
                contrary to local law or regulation.
              </p>
            </section>
            <section className="Consent">
              <h4> 2. Consent</h4>
              <p>
                Where processing of personal information is based on consent, we
                shall obtain the requisite consent at the time of collection of
                the personal information. In this regard, you consent to the
                processing of your personal information when you access our
                website, or use our services, content, features, technologies or
                functions offered on our website or other digital platforms. You
                can withdraw your consent at any time but such withdrawal will
                not affect the lawfulness of the processing of your data based
                on consent given before its withdrawal.
              </p>
            </section>
            <section className="Age-Restriction">
              <h4> 3. Age Restriction</h4>
              <p>
                You affirm that you are over the age of 18 and have the right to
                contract in your own name, and that you have read the above
                authorisation and fully understand its contents.
              </p>
            </section>
            <section className="Information-We-Collect">
              <h4> 4. Information We Collect</h4>
              <p>
                In providing financial services to you, we collect certain
                non-personal and personal information about you. Our policy is
                to keep this information confidential and strictly safeguarded,
                and to use or disclose it only as needed to provide services to
                you, or as permitted or required by law. This Policy is
                applicable to information for current and former clients.
              </p>

              <p>
                We collect a variety of information from our users and visitors
                to our website. As described below, some information is
                automatically collected when you visit our website, some you
                provide to us when filling out a form or communicating with us,
                and some provided to us by Third-Party Integration Application
                Programming Interface (API). There are three categories of
                information we collect:
              </p>

              <p>
                <span className="bold">
                  4.1 Information Collected Automatically:
                </span>{' '}
                Whenever you visit our website, our web servers automatically
                collect non-personal information such as the domain name of the
                internet access provider, the internet protocol address used to
                connect the computer to the internet, the average time spent on
                our website, pages viewed, information searched for, access
                times, and other relevant statistics.
              </p>

              <p>
                <span className="bold">4.2 Information You Provide Us:</span> If
                you provide us with personal information, by contacting us, or
                subscribing to our services we collect the following personal
                information:
              </p>

              <article className="push-left">
                <p>
                  <span className="bold">4.2.1</span> Name, gender, date and
                  place of birth; and your employment information;
                </p>

                <p>
                  <span className="bold">4.2.2</span> Information concerning
                  your identity e.g., a valid government-issued identity card
                  and your nationality;
                </p>

                <p>
                  <span className="bold">4.2.3</span> Payment information;
                </p>

                <p>
                  <span className="bold">4.2.4</span> Any other information you
                  provide to us.
                </p>
              </article>

              <p>
                <span className="bold">4.3</span> Information Provided by
                Third-Party Integration API: In order to provide our bespoke
                services to you, we collect your personally identifiable
                information through third-party platforms that provide us
                financial services. Some of the personal information we collect
                include:
              </p>

              <article className="push-left">
                <p>
                  <span className="bold">4.3.1</span> Bank Verification Number
                  (BVN);
                </p>

                <p>
                  <span className="bold">4.3.2</span> National Identity Number;
                </p>

                <p>
                  <span className="bold">4.3.3</span> Tax Identity Number;
                </p>

                <p>
                  <span className="bold">4.3.4</span> Employment Verification.
                </p>
              </article>
            </section>
            <section className="Using-Your-Personally-Identifiable-Information">
              <h4> 5. Using Your Personally Identifiable Information</h4>
              <p>
                “Personally Identifiable Information” means any information that
                (a) identifies or can be used to identify, contact, or locate
                the person to whom such information pertains, or (b) from which
                identification or contact information of an individual person
                can be derived.
              </p>

              <p>
                We primarily collect your information to ensure that we provide
                the most efficient financial service to you, monitor the use of
                and improve our website and other legitimate interests. Your
                information will solely be used and disclosed for the following
                purposes:
              </p>

              <article className="push-left">
                <p>
                  <span className="bold">5.1 </span>To help us verify your
                  identity;
                </p>

                <p>
                  <span className="bold">5.2 </span>We conduct necessary
                  investigations i.e., due diligence checks, Anti-Money
                  Laundering (AML)/ Countering the Financing of Terrorism (CFT)
                  checks and obtain information that we need to support our
                  regulatory obligations, e.g., information about transaction
                  details, detection of any suspicious and unusual activities;
                </p>

                <p>
                  <span className="bold">5.3 </span>We use this information to
                  detect and prevent financial crimes including fraud, financing
                  of terrorism and money laundering in order to ensure security
                  and business continuity;
                </p>

                <p>
                  <span className="bold">5.4 </span>To carry out our obligations
                  ensuing from any contracts entered into between you and us;
                </p>

                <p>
                  <span className="bold">5.5 </span>To provide you with the
                  products, services and information you request from us;
                </p>

                <p>
                  <span className="bold">5.6 </span>To assist you with enquiries
                  and improve our customer service;
                </p>

                <p>
                  <span className="bold">5.7 </span>To assist us in carrying out
                  marketing analysis and customer profiling (including
                  transactional information), conduct research, including
                  creating statistical and testing information;
                </p>

                <p>
                  <span className="bold">5.8 </span>To allow us to communicate
                  with you in any way (including e-mail, telephone, visit, and
                  text or multimedia messages);
                </p>

                <p>
                  <span className="bold">5.9 </span>For our billing and account
                  purposes;
                </p>

                <p>
                  <span className="bold">5.10</span> To help prevent and detect
                  fraud or loss;
                </p>

                <p>
                  <span className="bold">5.11</span> To update our records;
                </p>

                <p>
                  <span className="bold">5.12</span> To make recommendations and
                  suggestions to you about services offered by us unless you
                  have previously asked us not to do so;
                </p>

                <p>
                  <span className="bold">5.13</span> To send you service or
                  support messages, such as updates, security alerts, email
                  notifications and /or newsletters;
                </p>

                <p>
                  <span className="bold">5.14</span> To conduct investigations
                  and risk assessments; and
                </p>

                <p>
                  <span className="bold">5.15</span> For compliance with legal
                  and regulatory obligations.
                </p>
              </article>
            </section>
            <section className="Data-Accuracy">
              <h4> 6. Data Accuracy</h4>
              <p>
                Your personal data must be accurate and kept up to date. In this
                regard, Fluna shall ensure that any data it collects and/or
                processes is accurate and not misleading in a way that could be
                harmful to you; make efforts to keep your personal data updated
                where reasonable and applicable; and make timely efforts to
                correct or erase your personal data when inaccuracies are
                discovered.
              </p>
            </section>
            <section className="Other-Information">
              <h4> 7. Other Information</h4>
              <p>
                We may also collect information from you using cookies and other
                analytical tools especially when you use our products and
                services. More details are provided below in our section on
                Cookies.
              </p>
            </section>
            <section className="Data-Confidentiality">
              <h4> 8. Data Confidentiality</h4>
              <p>
                Your information is regarded as confidential and will not be
                divulged to any third party, except under legal and/or
                regulatory conditions. You have the right to request copies of
                any and all information we keep on you, if such requests are
                made in compliance with the Freedom of Information Act and other
                relevant enactments. While we are responsible for safeguarding
                the information entrusted to us, your role in fulfilling
                confidentiality duties includes, but is not limited to, adopting
                and enforcing appropriate security measures such as non-sharing
                of passwords and other platform login details and dealing with
                only authorized officers of Fluna.
              </p>
            </section>
            <section className="Disclosures">
              <h4> 9. Disclosures</h4>
              <p>
                We will not sell, publish, or disclose to third parties your
                personally identifiable information collected on our website,
                through our servers or otherwise obtained by us, other than to
                provide our services and as set forth in this Privacy Policy. We
                may share generic aggregated demographic information not linked
                to any personally identifiable information regarding visitors
                and Users with our business partners, trusted affiliates,
                professional advisers and advertisers for the purposes outlined
                above. We may share your information with these third parties
                for those limited purposes if you have given us your permission
                and in compliance with applicable data protection laws.
              </p>

              <article className="push-left">
                <p>
                  <span className="bold">9.1 </span>We may request and provide
                  information about you from and to third parties to provide our
                  services.
                </p>

                <p>
                  <span className="bold">9.2 </span>We will notify you as soon
                  as we become aware of a harmful data breach which may result
                  in a risk of your rights and freedom.
                </p>

                <p>
                  <span className="bold">9.3 </span>You have the right to
                  request an erasure of your data at any time.
                </p>

                <p>
                  <span className="bold">9.4 </span>We will notify you if we are
                  transferring your data.
                </p>

                <p>
                  <span className="bold">9.5 </span>You may request at any time
                  that we halt further dissemination of your data or cease to
                  use your data.
                </p>

                <p>
                  <span className="bold">9.6 </span>If you submit content in a
                  public forum or a social media post, or use a similar feature
                  on our website, that content is publicly visible.
                </p>

                <p>
                  <span className="bold">9.7 </span>We may disclose Personally
                  Identifiable Information if required to do so by law or in the
                  good faith belief that such action is necessary to (a) conform
                  with the requirements of the law or comply with legal process
                  served on us, or (b) act in urgent circumstances to protect
                  the personal safety of users of our service or members of the
                  public.
                </p>

                <p>
                  <span className="bold">9.8 </span>To the extent practicable
                  and legally permitted, we will attempt to advise you prior to
                  any such disclosure, so that you may seek a protective order
                  or other relief limiting such disclosure.
                </p>
              </article>
            </section>
            <section className="Transfer-of-Personal-Data">
              <h4> 10. Transfer of Personal Data</h4>
              <p>
                <span className="bold">10.1</span> Third Party Processor
              </p>
              <p>
                We may engage the services of third parties in order to process
                your personal data. The processing by such third parties shall
                be governed by a written contract with Fluna to ensure adequate
                protection and security measures are put in place by the third
                party for the protection of your personal data in accordance
                with the terms of this Policy and applicable data protection
                laws.
              </p>

              <p>
                <span className="bold">10.2</span> International Transfers
              </p>

              <article className="push-left">
                <p>
                  <span className="bold">10.2.1</span> Your information may be
                  transferred to a foreign country or international organisation
                  for the purpose of providing our service to you. We will
                  ensure that there are adequate data protection laws in the
                  recipient country or organisation before transferring your
                  information.
                </p>

                <p>
                  <span className="bold">10.2.2</span> We will also only
                  transfer your personal information where:
                </p>
                <article className="push-left">
                  <p>a. Your explicit consent has been obtained;</p>
                  <p>
                    b. The transfer is necessary for the performance of a
                    contract between you and Fluna;
                  </p>
                  <p>
                    c. The transfer is necessary to conclude a contract between
                    Fluna and a third party in your interest;
                  </p>
                  <p>
                    d. The transfer is necessary for reason of public interest;
                  </p>
                  <p>
                    e. The transfer is for the establishment, exercise or
                    defense of legal claims;
                  </p>
                  <p>
                    f. The transfer is necessary in order to protect your vital
                    interests or the interests of other persons, where the you
                    are physically or legally incapable of giving consent.
                  </p>
                </article>
              </article>

              <p>
                <span className="bold">10.3</span> Provided, in all
                circumstances, that you have been manifestly made to understand
                the specific principle(s) of data protection that are likely to
                be relied on in the event of transfer to a third country, this
                provision shall not apply to any instance where you are
                answerable in duly established legal action for any civil or
                criminal claim in another country.
              </p>

              <p>
                <span className="bold">10.4</span> We will take all necessary
                steps to ensure that your personal data is transmitted in a safe
                and secure manner. Details of the protection given to your
                information when it is transferred to a foreign country shall be
                provided to you upon request.
              </p>
            </section>
            <section className="Your-Rights">
              <h4> 11. Your Rights</h4>
              <p>
                Subject to certain limitations and exceptions, you are entitled
                to the following principal rights under the General Data
                Protection Regulation (GDPR):
              </p>

              <article className="push-left">
                <p>
                  <span className="bold">11.1</span> You have the right to be
                  notified if we are transferring your personal information.
                </p>

                <p>
                  <span className="bold">11.2</span> You have the right to
                  request an erasure of your personal information at any time.
                </p>

                <p>
                  <span className="bold">11.3</span> You have the right to
                  request that we rectify inaccurate personal information.
                </p>

                <p>
                  <span className="bold">11.4</span> You may request at any time
                  that we halt further dissemination of your data or cease to
                  use your personal information.
                </p>

                <p>
                  <span className="bold">11.5</span> You have the right to
                  request for copies of your personal information.
                </p>
              </article>
            </section>
            <section className="Website-Security">
              <h4> 12. Website Security</h4>
              <p>
                We are committed to ensuring that your information is secure. In
                order to prevent unauthorised access or disclosure, we have put
                in place suitable physical, electronic and managerial procedures
                such as secure sockets layer (SSL) to safeguard and secure the
                information we collect online, we use encryption tools when
                accepting and transmitting delicate visitor information through
                our website; some of the other safeguards we use are firewalls
                and physical access controls to our data centres, and
                information access authorization controls.
              </p>
            </section>
            <section className="Training">
              <h4> 13. Training</h4>
              <p>
                We shall ensure that employees who collect, access and process
                your personal data receive adequate data privacy and protection
                training in order to develop the necessary knowledge, skills and
                competence required to effectively manage the compliance
                framework under this Policy and applicable data protection laws,
                with regard to the protection of personal data. On an annual
                basis, we shall develop a capacity building plan for our
                employees on data privacy and protection in accordance with
                applicable data protection laws.
              </p>
            </section>
            <section className="Use-of-Cookies">
              <h4> 14. Use of Cookies</h4>
              <p>
                We use cookies to identify you as a user and make your user
                experience easier, customise our services, content and
                advertising, help you ensure that your account security is not
                compromised, mitigate risk and prevent fraud and to promote
                trust and safety on our website. Cookies allow our servers to
                remember your account log-in information when you visit our
                website, IP addresses, date and time of visits, monitor web
                traffic and prevent fraudulent activities. If your browser or
                browser add-on permits, you have the choice to disable cookies
                on our website; however, this may limit your ability to use our
                website.
              </p>
            </section>
            <section className="The-Data-We-Retain">
              <h4> 15. The Data We Retain</h4>
              <p>
                We will retain your information for as long as needed to provide
                you with our services, comply with our legal and statutory
                obligations or verify your information with a financial
                institution. We are statutory obligated to retain the data you
                provide us with in order to process transactions, ensure
                settlements, make refunds, identify fraud and in compliance with
                laws and regulatory guidelines applicable to us, our banking
                providers and credit card processors.
              </p>
            </section>
            <section className="Data-Breachment-Management-Procedure">
              <h4> 16. Data Breachment Management Procedure</h4>
              <article className="push-left">
                <p>
                  <span className="bold">16.1 I</span>n the event where there is
                  any accidental or unlawful destruction, processing, loss,
                  alteration, unauthorized disclosure of, or access to your
                  personal data, we shall:
                </p>
                <article className="push-left">
                  <p>
                    <span className="bold">16.1.1</span> notify you within 24
                    hours of the occurrence of the data breach;
                  </p>

                  <p>
                    <span className="bold">16.1.2</span> properly investigate
                    the breach and take the necessary steps to mitigate such
                    breach;
                  </p>

                  <p>
                    <span className="bold">16.1.3</span> identify remediation
                    requirements and track the resolution of such breach; and
                  </p>

                  <p>
                    <span className="bold">16.1.4</span> notify applicable
                    regulatory authorities in charge of data protection, where
                    necessary.
                  </p>
                </article>
              </article>
            </section>
            <section className="Links-to-Third-Party-Websites">
              <h4> 17. Links to Third Party Websites</h4>
              <article className="push-left">
                <p>
                  <span className="bold">17.1</span> Our website may contain
                  links to third-party websites or services that are not owned
                  or controlled by us.
                </p>

                <p>
                  <span className="bold">17.2</span> We have no control over,
                  and assume no responsibility for, the content, privacy
                  policies, or practices of any third-party websites or
                  services. You further acknowledge and agree that we shall not
                  be responsible or liable, directly or indirectly, for any
                  damage or loss caused or alleged to be caused by or in
                  connection with use of or reliance on any such content, goods
                  or services available on or through any such websites or
                  services.
                </p>

                <p>
                  <span className="bold">17.3</span> We strongly advise you to
                  read the terms and conditions and privacy policies of any
                  third-party websites or services that you visit.
                </p>
              </article>
            </section>
            <section className="Limitation-of-Liability">
              <p>
                We exercise reasonable efforts to safeguard the security and
                confidentiality of your personal data; however, we will not be
                liable for unauthorised disclosure of personal data that occurs
                through no fault of ours.
              </p>
            </section>
            <section className="Changes-to-the-Privacy-Policy">
              <h4> 18. Changes to the Privacy Policy</h4>
              <p>
                Changes may be made to this Privacy Policy from time. Whenever
                such changes are made, we will notify you. These changes will
                take effect immediately after you have been notified. If you do
                not agree with the terms of this Policy or any updated version
                of this Policy, you should not use the website or our services.
                Your continued use of the website or our services after you have
                been notified indicates your acceptance of the Policy or any
                updated version of the Policy.
              </p>
            </section>
            <section className="Contact-Us">
              <h4> 19. Contact Us</h4>
              <p>
                If you would like more information or you have any comments or
                questions on our Privacy Policy, please contact us at
                info@fluna.co
              </p>

              <p>This Policy is effective as of June 22nd, 2022</p>
              <p>Last updated: June 22nd, 2022</p>
            </section>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PrivacyPolicy
