import React, { PropsWithChildren } from 'react'
import * as Sentry from '@sentry/browser'
import Error from '../components/v2/Error'

type IProps = PropsWithChildren<{
  [index: string]: any
  children: React.ReactNode
}>

interface IState {
  hasError?: boolean
  eventId: any
  is404?: boolean
}

export default class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = { eventId: null, hasError: false, is404: false }
  }

  static getDerivedStateFromError(error: any) {
    if (error?.status === 404) {
      return { hasError: true, is404: true }
    }
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log('component caught', error)
    if (error?.status === 404) {
      this.setState({ is404: true })
    } else if (error.toString().indexOf('ChunkLoadError') > -1) {
      window.location.reload()
    } else {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.state.is404) {
        return <Error title="Page Not Found" type="404" />
      }
      return (
        <Error
          onClick={() => {
            Sentry.showReportDialog({
              eventId: this.state.eventId,
            })
          }}
          title="Something Went Wrong"
          type="error"
        />
      )
    }

    return this.props.children
  }
}
