import AuthenticationLayout from 'layout/v2/authentication/AuthenticationLayout'
import React, { lazy } from 'react'

export const OnboardingPage = lazy(() => import('pages/v2/onboarding'))
export const OnboardingFinalPage = lazy(
  () => import('pages/v2/onboarding/status')
)

const OnboardingRoutes = [
  {
    path: 'onboarding',
    element: <AuthenticationLayout />,
    children: [
      {
        path: '',
        element: <OnboardingPage />,
      },
      {
        path: 'status',
        element: <OnboardingFinalPage />,
      },
    ],
  },
]

export default OnboardingRoutes
