import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  DueInterestBankPayouts,
  Investment,
  Payments,
  References,
  Withdrawals,
} from 'API'
import { ErrorHandler } from 'helpers/handlers'
import {
  getAccountTransactionsUngrouped,
  modifiedTransactions,
} from 'services/v2/transactionActivityService'

export interface TransactionActivityState {
  investments: modifiedTransactions<Investment>[]
  deposits: modifiedTransactions<References>[]
  withdrawals: modifiedTransactions<Withdrawals>[]
  interest: modifiedTransactions<Payments>[]
  bankPayouts: modifiedTransactions<DueInterestBankPayouts>[]
  loadingTransactions: boolean
}

const initialState: TransactionActivityState = {
  investments: [],
  deposits: [],
  withdrawals: [],
  interest: [],
  bankPayouts: [],
  loadingTransactions: false,
}

export const fetchTransactions = createAsyncThunk(
  'transactionActivity/fetchTransactions',
  async ({
    uid,
    clientId,
    accountId,
  }: {
    uid: string
    clientId: string
    accountId: string
  }) => {
    try {
      const { data } = await getAccountTransactionsUngrouped(
        uid,
        clientId,
        accountId
      )

      const {
        deposits: userDeposits,
        withdrawals: userWithdrawals,
        principal: userInvestments,
        interests: userInterests,
        bankPayouts: userBankPayouts,
      } = data

      const userTransactions = {
        userInvestments,
        userDeposits,
        userWithdrawals,
        userInterests,
        userBankPayouts,
      }

      return userTransactions
    } catch (err) {
      console.log('UserFetchErr', err)
      ErrorHandler({ message: 'Unable to fetch users data' })
      return {
        userInvestments: [],
        userDeposits: [],
        userWithdrawals: [],
        userInterests: [],
        userBankPayouts: [],
      }
    }
  }
)

const transactionActivitySlice = createSlice({
  name: 'transactionActivity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTransactions.pending, (state) => {
      state.loadingTransactions = true
    })
    builder.addCase(fetchTransactions.fulfilled, (state, action) => {
      const {
        userInvestments,
        userDeposits,
        userWithdrawals,
        userInterests,
        userBankPayouts,
      } = action.payload

      state.loadingTransactions = false
      state.investments = userInvestments
      state.deposits = userDeposits
      state.withdrawals = userWithdrawals
      state.interest = userInterests
      state.bankPayouts = userBankPayouts
    })
    builder.addCase(fetchTransactions.rejected, (state) => {
      state.loadingTransactions = false
      state.investments = []
      state.deposits = []
      state.withdrawals = []
      state.interest = []
      state.bankPayouts = []
    })
  },
})

// export const {} = transactionActivitySlice.actions

export default transactionActivitySlice.reducer
