/* eslint-disable @typescript-eslint/no-unused-vars */
import mixpanel from 'mixpanel-browser'
// import store from '../redux/store'
// const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN as unknown as string
// mixpanel.init(MIXPANEL_TOKEN)

// const env_check = process.env.NODE_ENV === 'production' || 'dev'
// const isTest = process.env.NODE_ENV === 'test'
interface Dict {
  [key: string]: unknown
}
const actions = {
  identify: (id: string): void => {
    // env_check && !isTest && mixpanel.identify(id)
  },
  alias: (id: string): void => {
    // env_check && !isTest && mixpanel.alias(id)
  },
  track: (name: string, props?: Dict): void => {
    // const userId = store.getState()?.auth?.user?.id
    // if (env_check && !isTest) {
    //   mixpanel.identify(userId)
    //   mixpanel.track(name, {
    //     ...props,
    //     distinct_id: userId,
    //   })
    // }
  },
  people: {
    set: (props: Dict): void => {
      // env_check && !isTest && mixpanel.people.set(props)
    },
  },
  signout: (): void => {
    // mixpanel.reset()
  },
}

export const MixPanel = actions
